<div class="demo-message-container" *ngIf="showDemoMessage | async">
    <div class="demo-message-inner fx-center-vertical" data-testid="signup-banner">
        <div class="demo-message-icon">
            <svg class="nu-icon nu-icon-med">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-fill"></use>
            </svg>
        </div>
        <div class="demo-message-text body">
            <span>
                {{translate.get( 'DEMO_MESSAGES.HEADER_MSG_PART1' )|async}}
            </span>
            <span>
                {{translate.get( 'DEMO_MESSAGES.HEADER_MSG_PART2')|async}}
            </span>
            <a
                [trackAction]="{ event: 'conversion.demo.banner.click'}"
                class="demo-message-auth-link btn-link body--bold"
                data-testid="signup-link"
                (click)="showAuthDialog()">
                {{translate.get( 'DEMO_MESSAGES.SINGING_UP')|async}}
            </a>
        </div>
    </div>
</div>
