import {
    Component,
    ChangeDetectionStrategy,
    Inject, OnDestroy,
    AfterViewInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
// import { Authentication } from '../../user/authentication.svc';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'flux-core';
import {  filter, take, switchMap } from 'rxjs/operators';
import { UserLocator } from '../../user/user-locator.svc';
import { Authentication } from '../../user/authentication.svc';
import { UserStatus } from '../../user/model/user-info.mdl';

/**
 * This is the demo message component.
 *
 * @author niroshana
 * @since 2019-02-20
 */

@Component({
    selector: 'demo-message',
    templateUrl: './demo-message.cmp.html',
    styleUrls: [ 'demo-message.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoMessage implements OnDestroy, AfterViewInit {

    /**
     * A behavior subject that emits boolean values to show/hide the demo message from the ui
     */
    public showDemoMessage: BehaviorSubject<boolean>;

    /**
     * Event emitter to emit demo user sign in. value will be true if the user changes
     * during the sign up or sign in. otherwise the value will be false.
     */
    @Output() public demoSignIn: Subject<boolean> = new Subject();

    /**
     * Current user data subscription, must remove upon destroy.
     */
    private userSub: Subscription;

    /**
     * Constructor
     */
    public constructor(
        // TODO to remove it later if this demo banner feature is disabled forever in the future.
        private locator: UserLocator,
        @Inject( StateService ) protected state: StateService<any, any>,
        private authService: Authentication,
        public translate: TranslateService,
    ) {
        this.showDemoMessage = new BehaviorSubject( false );
    }

    /**
     * Check if the current user is a demo user, and if true show the demo message
     */
    // TODO to remove it later if this demo banner feature is disabled forever in the future.
    public ngAfterViewInit() {
        this.userSub = this.locator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( userModel => !!userModel ),
        ).subscribe( user => {
            if ( user.status === UserStatus.DEMO ) {
                this.showDemoMessage.next( true );
            } else {
                this.showDemoMessage.next( false );
            }
        });
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    public ngOnDestroy() {
        this.userSub.unsubscribe();
    }

    /**
     * Open authentication window when demo user clicks on sign up link
     * If the user sign up or sign in and user has a free plan, this will open a new browser window
     * to the plans page, othervice the app will be reloaded to switch the user from demo plan.
     * NOTE: Reloading is done due to user subscription not sending updates to the user.
     * When the app can behave according to user changes, use that functionality to switch user plan.
     */
    public showAuthDialog() {
        const demoUserId: string = this.state.get( 'CurrentUser' );
        this.locator.getUserData().pipe(
            take( 1 ),
            switchMap( user => {
                const inputs: any = user.email.includes( user.id ) ? {} : { email: user.email };
                return this.authService.showSignUp( inputs );
            }),
        ).subscribe({
            complete: () => {
                this.demoSignIn.next( this.state.get( 'CurrentUser' ) !== demoUserId );
            },
            error: () => {
                // User closed the sign up window without any action
                // Allow continuing the demo session
            },
        });
    }
}
