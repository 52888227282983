import { Injectable } from '@angular/core';
import { AbstractHttpCommand, AppConfig, Command, CommandInterfaces, ResourceLoader } from 'flux-core';
import { EMPTY, Observable } from 'rxjs';
import { NucleusAuthentication } from '../../../system/nucleus-authentication';
import { ResourceStatus } from 'flux-definition';

/**
 * This command is used to upload a list of imported images to the
 * server. The image content along with its metadata is sent
 * to the server to be stored.
 * Importent: If you are importing only one image you can use the `UploadImage` command.
 *
 * Input data format:
 *  {
 *      files: true,
 *      type: true
 *  }
 *
 * @since   2023-05-26
 * @author  Raseakran
 */
@Injectable()
@Command()
export class UploadImages extends AbstractHttpCommand  {

    /**
     * Input data definition
     */
    public static get dataDefinition(): {}  {
        return {
            files: true,
        };
    }

    /**
     * Command interfaces
     */
    public static get implements(): Array<CommandInterfaces> {
        return [
            'IUserCommand', 'INeutrinoRestCommand', 'IPluginCommand', 'IAllowAnonymous',
        ];
    }

    /**
     * Returns the command result type for REST endpoint
     */
    public static get resultType(): any {
        return { result : Object };
    }

    /**
     * Image Types used here to create extension from it.
     */
    protected imageTypes = {
        'image/png': 'png', 'image/jpeg': 'jpg', 'image/jpg': 'jpg',
        'image/gif': 'gif', 'image/bmp': 'bmp', 'image/x-icon': 'ico',
        'image/svg+xml': 'svg',
    };

    /**
     * This holds the hash value of the image
     */
    protected hash: string;

    constructor( protected auth: NucleusAuthentication,
                 protected loader: ResourceLoader ) {
        super() /*istanbul ignore next */;
    }

    /**
     * Returns REST endpoint URL for this command
     */
    public get httpUrl(): string {
        return AppConfig.get ( 'REST_API_BASE_URL' )
            + '/' + AppConfig.get( 'MESSAGE_PROTOCOL_VERSION' )
            + '/1/user/upload-images';
    }

    /**
     * Attaches the auth token to command data
     * The REST execution step requires it.
     * Add missing extension.
     */
    public prepareData(): any {
        this.data.auth = this.auth.token;
        for ( const file of this.data.files ) {
            file.extension = this.imageTypes[ this.data.type ] || '';
        }
    }

    /**
     * Command execute
     */
    public execute(): Observable<any> {
        return EMPTY;
    }

    /**
     * This function will update the cached resource
     * status as 'uploaded' on the success.
     */
    public executeResult( response: any ) {
        if ( response ) {
            for ( const file of this.data.files ) {
                this.loader.setResourceStatus( this.getImageUrl( file.hash ), ResourceStatus.Uploaded );
            }
        }
        return true;
    }

    /**
     * Retrieves the url of the image.
     */
    protected getImageUrl( hash: string ): string {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' ) + hash;
    }
}

Object.defineProperty( UploadImages, 'name', {
    value: 'UploadImages',
});
