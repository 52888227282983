<div *ngIf="(isItemListEmpty | async)" class="empty-state-section" >
    <message-card [title]="emptyStateTitle" [body]="emptyStateBody"
                        [titleIcon]="emptyStateIcon"></message-card>
</div>
<div [hidden]="(isItemListEmpty | async)" class="edata-library-panel-container" tooltip-tour-step="data-3">
    <div *ngIf="showUpdatePanel | async" class="update-main-panel">
        <vertical-container class="update-panel" [flexProps]="{gap: '8px'}">
            <vertical-container [flexProps]="{gap: '8px'}">
                <horizontal-container [flexProps]="{gap: '20px'}">
                    <span [hidden]="updatingFromSource | async" class="caption caption--bold">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.HEADING' | translate }}</span>
                    <span [hidden]="(updatingFromSource | async) === false" class="caption caption--bold">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.UPDATING' | translate }}</span>
                    <svg class="nu-icon">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-place-holder-google-sheet"></use>
                    </svg>
                </horizontal-container>
                <span [hidden]="updatingFromSource | async" class="caption upadte-content">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.DESCRIPTION' | translate }}</span>
                <span [hidden]="(updatingFromSource | async) === false">
                    <loading-bubbles class="fx-center" color="xmedium-grey"></loading-bubbles>
                </span>
                <horizontal-container style="padding: 8px 0px" [flexProps]="{gap: '10px'}">
                    <button class="btn-small btn-primary" [disabled]="updatingFromSource | async" (click)="updateFromSource()">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.UPDATE' | translate }}</button>
                    <button class="btn-small btn-secondary" [disabled]="updatingFromSource | async" (click)="showUpdatePanel.next(false)">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.CANCEL' | translate }}</button>
                </horizontal-container>
            </vertical-container>
            <horizontal-container [flexProps]="{gap: '4px'}">
                <svg class="nu-icon nu-icon-xsmall upadte-content">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-recent"></use>
                </svg>
                <span class="caption caption--sm upadte-content">{{ 'EDATA_PANEL.UPDATE_GS_PANEL.LAST_UPDATED' | translate }} {{ lastUpdateTime | async }}</span>
            </horizontal-container>  
        </vertical-container>
    </div>
    <!-- div>
        <div class="update-panel-header">
            <div class="update-panel-title">
            </div>
        </div>
        <div class="update-panel-content">
            <div class="update-panel-content-inner">
                <div class="update-panel-description"></div>
                <div class="update-panel-content-actions">
                    
                </div>
            </div>
        </div>
    </div -->
    <edata-smart-set-group
        (closed)="handleSmartSetClose( $event )">
    </edata-smart-set-group>
    <div>
        <div *ngIf="( searchResult | async )?.length > 0">
            <edata-search-results-panel [searchResult]="searchResult" [searchQuery]="searchQuery" [eDataModel]="eDataModel" (goBack)="emptyResult()"></edata-search-results-panel>
        </div>
    </div>
    <div class="menu-item" *ngIf="smartSetsList && (smartSetsList | keyvalue).length > 0 && ( searchResult | async )?.length === 0">
        <div class="database-collapsible-menu-container" [class.selected]="expanded">
            <div class="database-collapsible-menu-container-inner" (click)="toggleSubMenu()">
                <div class="sidebar-item-icon" [class.expanded]="expanded">
                    <svg class="nu-icon">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-thick"></use>
                    </svg>
                </div>
                <div class="database-item-label">
                    <svg class="nu-icon">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-smart-filter"></use>
                    </svg>
                    <span>Saved Sets</span>
                </div>
            </div>
            <div *ngIf="expanded">
                <edata-library-smartset-item            
                    *ngFor="let item of smartSetsList | keyvalue"
                    id="item.id"
                    [label]="item.value.name"
                    [icon]="'nu-icon xmedium-grey'"
                    [iconHref]="'./assets/icons/symbol-defs.svg#nu-ic-database'"
                    [indent]="0"
                    [isVisible]="smartSetClose | async"
                    (dragstart)="handleSmartSetDragStart( $event, item.value )"
                    (titleChanged)="handleSmartSetNameChange( $event, item.value )"
                    (deleteSmartSet)="handleSmartSetDeleteChange( item.value )">
                </edata-library-smartset-item>
            </div>
        </div>
    </div>
    <div [class.fx-hidden]="( searchResult | async )?.length > 0" class="edata-library-panel" tooltip-tour-step="data-3">
        <collapsible-menu #entityGroups class="entity-type-collabsible-menu">
        </collapsible-menu>
    </div>
</div>