import { CollaboratorType } from 'flux-diagram/models';

export class TeamShareModel {

    /**
     * Sharing with anyone also added to teamShare as another entity.
     * For that entity the team Id is specified as "All".
     * When checking access permission if the diagram/project contains
     */
    public static readonly ALL = 'All';

    /**
     * Id if the teamShare object( A randome Id )
     */
    public id: string;

    /**
     * Defines the privacy role
     */
    public role: CollaboratorType;

    /**
     * Defines the access level
     */
    public teamId: string;

    /**
     * Hash value to access the resource.
     */
    public hash: string;

    /**
     * A boolean to denote that the resource can be listed in dashboard of the team.
     */
    public discoverable: boolean = true;

    public constructor( id: string, teamId: string, role: CollaboratorType, hash?: string ) {
        this.id = id;
        this.teamId = teamId;
        this.role = role;
        if ( !!hash ) {
            this.hash = hash;
        }
    }

    /**
     * Returns true if the resources access level is set to public.
     */
    public canAnyoneAccess(): boolean {
        return this.teamId === TeamShareModel.ALL;
    }
}
