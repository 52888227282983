import { fromEvent, empty, timer, Observable, Subscription } from 'rxjs';
import { tap, filter, switchMap } from 'rxjs/operators';
import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ElementRef,
    ViewChild,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Animation } from '../animation';
import { Tracker } from '../../tracker/tracker';

/**
 * This interface defines the information needed for
 * drop down menu item.
 */
export interface IDropDownMenuItem {
    /**
     * Display name for the menu item shown in the
     * dropdown list
     */
    label: string;

    /**
     * Vaue that get from the menu item when it's get
     * submitted
     */
    value: any;

    /**
     * This indicates whether this item needs to be selected by default
     */
    select?: boolean;

    /**
     * To identify if this item is a separator in the dropdown.
     */
    separator?: boolean;
}

@Component({
    template: `
        <div tooltip
            placement="bottom"
            [deactivated]="!tooltipText"
            [class.disabled]="disabled"
            class="dropdown-container btn-small btn-secondary {{theme}} {{dropdownSize}}"
            (click)="toggleMenu()">
            <div class="dropdown-selected">
                <span class="dropdown-value body">{{ selectedItem?.label }}</span>
                <svg class="nu-dropdown-arrow nu-icon-small nu-icon">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                </svg>
            </div>
            <ul class="dropdown-list {{direction}}" *ngIf="onShow | async" #dropDownMenu>
                <li *ngFor="let item of menuItems" class="fx-center-vertical btn-free-size" [class.btn-list-item]="!item.separator" (click)="selectItem($event, item)">
                    <div class="dropdown-selected-tick" *ngIf="showSelected && !item.separator">
                        <svg class="nu-icon" *ngIf="item.select">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-tick-thin"></use>
                        </svg>
                    </div>
                    <span class="body" *ngIf="!item.separator">{{item.label}}</span>
                    <div class="separator" *ngIf="item.separator"></div>
                </li>
            </ul>
            <span class="tooltip-content" translate >{{ tooltipText }}</span>
        </div>
    `,
    selector: 'drop-down',
    styleUrls: [ './drop-down.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * DropDown Compoenent
 * This is a toggleable menu that allows the user to choose one value from a predefined list.
 *
 * User needs to pass a menu items as an input for this component.
 * i.e user can use the component like this
 *      <dropdown [menuItems]="dropdownValues" (select)="action($event.item)"></dropdown>
 */

export class DropDown implements OnChanges, OnDestroy {

    /**
     * A class which specifies in which direction the dropdown should open.
     * This can be up or down.
     */
    @Input() public direction: 'up' | 'down' = 'down';

    /**
     * A class which specifies the size of the dropdown. Can be large, extra-large.
     * If left empty, it will apply styles for a normal sized dropdown
     */
    @Input() public dropdownSize: string;

    /**
     * A class that gets added to specify which theme the dropdown belongs to.
     * Ex: theme = “dark” will add the clas “dark” and apply styles which belong to the dark theme of the application.
     */
    @Input() public theme: string;

    /**
     * Items that needs to be added to the menu list.
     */
    @Input()
    public menuItems: IDropDownMenuItem[];

    /**
     * The tracking id for on selected events.
     */
    @Input()
    public onSelectTrackingId: string;

    @Input()
    public showSelected: boolean = true;

    /**
     * Disable dropdown menu.
     */
    @Input()
    public disabled: boolean = false;

    /**
     * String path to the text tranlsation. If this string is given then it will show the tooltip,
     * otherwise it will not show the tooltip.
     */
    @Input()
    public tooltipText: string;

    /**
     * An observable which emits a value that got selected in the
     * menu list.
     */
    public select: Subject<any>;

    /**
     * This indicates whether the drop down menu needs to show or not.
     */
    public onShow: BehaviorSubject<boolean>;

    /**
     * This holds a reference to the dropdown menu element where the menu list get added.
     */
    protected _dropDownMenu: any;

    /**
     * The list of subscriptions held by this class
     */
    protected subscriptions: Subscription[] = [];

    /**
     * Holds the property to show/hide the menu list.
     */
    protected _menuActive: boolean = false;

    /**
     * This holds the currently selected menu items
     */
    protected _selectedItem: IDropDownMenuItem;

    constructor( protected elem: ElementRef ) {
        this.select = new Subject();
        this.onShow = new BehaviorSubject( false );
        const clickSub = this.listenForOutSideClick().subscribe();

        this.subscriptions.push( clickSub );
    }

    @ViewChild( 'dropDownMenu', { read: ElementRef })
    public set dropDownMenu( menu: any ) {
        this._dropDownMenu = menu;
    }

    public set menuActive( val: boolean ) {
        this._menuActive = val;
        this.onShow.next( val );
    }

    public get menuActive(): boolean {
        return this._menuActive;
    }

    public get selectedItem(): IDropDownMenuItem {
        return this._selectedItem;
    }

    public ngOnChanges() {
        this._selectedItem = this.menuItems.find( item => item.select );
    }

    public ngOnDestroy() {
        while ( this.subscriptions.length > 0 ) {
            this.subscriptions.pop().unsubscribe();
        }
     }

    /**
     * Handles the click event on the selected item
     */
    public selectItem( event: Event, value: IDropDownMenuItem ): void {
        if ( !value.separator ) {
            this._selectedItem = value;
            this.select.next( value );
            this.subscriptions.push( this.hideMenu().subscribe());
            if ( !!this.onSelectTrackingId ) {
                Tracker.track( this.onSelectTrackingId, {
                    value1Type: 'selectedValue', value1: value.value,
                    value2Type: 'selectedLabel', value2: value.label,
                });
            }
        }
        event.stopPropagation();
    }

    /**
     * Handles the click event on the selection element
     */
    public toggleMenu( ) {
        if ( this.disabled ) {
            return;
        }
        if ( this.menuActive ) {
            this.subscriptions.push( this.hideMenu().subscribe());
            return;
        }
        this.subscriptions.push( this.showMenu().subscribe());
    }

    /**
     * shows dropdown menu
     */
    public showMenu(): Observable<any> {
        this.menuActive = true;
        return timer( 0 ).pipe(
            switchMap(() => this.showAnimation().start()));
    }

    /**
     * Hides dropdown menu
     */
    public hideMenu(): Observable<any> {
        if ( this.menuActive ) {
            return this.hideAnimation().start().pipe(
                tap({
                    complete: () => this.menuActive = false,
                }));
        }
        return empty();
    }

    /**
     * This listens for the mousedown event outside of the dropdown
     * menu and hide the drop down menu.
     */
    protected listenForOutSideClick(): Observable<any> {
        return fromEvent( document, 'mousedown' ).pipe(
            filter(( event: MouseEvent ) => !this.elem.nativeElement.contains( event.target )),
            switchMap(() => this.hideMenu()));
    }

    /**
     * Animate the given component by setting the height to 0 and
     * return the defined animation
     */
    protected hideAnimation(): Animation {
        const transition = new Animation({
            from: { opacity: '1', transform: 'translateY( 0 )' },
            to: { opacity: '0', transform: 'translateY( -5px )' },
            transitionProperty: 'opacity, transform',
            duration: 100,
            easing: 'linear',
        });
        transition.element = this._dropDownMenu.nativeElement as HTMLElement;
        return transition;
    }

    /**
     * Animate the given component by setting to given height and return
     * the animation
     */
    protected showAnimation(): Animation {
        const transition = new Animation({
            from: { opacity: '0', transform: 'translateY( -5px )' },
            to: { opacity: '1', transform: 'translateY( 0 )' },
            transitionProperty: 'opacity, transform',
            duration: 100,
            easing: 'linear',
        });
        transition.element = this._dropDownMenu.nativeElement as HTMLElement;
        return transition;
    }

}
