import { Injectable } from '@angular/core';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Command, StateService, Tracker } from 'flux-core';
import { IShapeDefinition } from 'flux-definition';
import { LogicClassFactory, TextFormatter, TextPostion } from 'flux-diagram-composer';
import { mapTo, switchMap, take, tap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { cloneDeep, pick, merge } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';

/**
 * SwitchShapeAny
 * Switch shape A to shape B
 */
@Injectable()
@Command()
export class SwitchShapeAny extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
     public data: {
        shapeIds: Array<string>;
        newDefId: string;
        newVersion: number;
    };

    /**
     * These data are specific to the shape and should reset when switching to a new shape
     */
    protected shapeSpecificDataMap: any = {
        'creately.orgchart.circularimagetop' : [ 'maskType', 'imagePlacement', 'base' ],
        'creately.orgchart.circularimagenobase' : [ 'maskType', 'imagePlacement', 'base' ],
        'creately.orgchart.squareimagetop' : [ 'maskType', 'imagePlacement', 'base' ],
        'creately.orgchart.squareimageleft' : [ 'maskType', 'imagePlacement', 'base' ],
    };

    protected dontPreserveStyles = [
        'creately.mindmap.texttopic',
        'creately.mindmap.idea',
        'creately.mindmap.thintopic',
        'creately.concept-map.cell',
        'creately.concept-map.bubble',
    ];


    /**
     * These data are specific to the shape and should reset when switching to a new shape
     */
    protected shapesToResetScale: string[] = [ 'creately.bpmn.choreograph' ];

    protected formatter: TextFormatter;

    /**
     * Inject the state service to get the list of selected shape ids.
     * Inject DefinitionLocator to get new shape's definition
     * Add text formatter to cmd to perform text styling related functions
     */
    constructor(
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any>,
        protected ds: DiagramChangeService ) {
        super( ds );
        this.formatter = new TextFormatter();
        this.state = state;
    }

    /**
     * Adjust shape based on preferences and new shape definition.
     */
    public prepareData() {
        this.resultData = { shapeIds: this.data.shapeIds };
        let def;
        return this.defLocator.getDefinition( this.data.newDefId, this.data.newVersion ).pipe(
            take( 1 ),
            switchMap(( defn: IShapeDefinition ) => {
                def = cloneDeep( defn );
                const obs = [ of( def ),
                    ...this.data.shapeIds.map( id => {
                        const shape:  any = this.changeModel.shapes[id];
                        // Add tracking
                        Tracker.track( 'canvas.toolbar.shape.change', {
                            value1: def.name,
                            value2: shape.name,
                            value3: def.defId.split( '.' )[1],
                        });

                        const currShapeDefKey = shape.defId.replace( /\./g, '_' ) + '_' + shape.version;
                        if ( !shape.switchHistory ) {
                            shape.switchHistory = {};
                        }

                        const textData = {};
                        const dataItems = {};
                        Object.values( shape.texts ).forEach(( text: any ) => {
                            textData[ text.id ] =  pick( text, [
                                'id',
                                'primary',
                                'width',
                                'height',
                                'x',
                                'y',
                                'xType',
                                'yType',
                                'alignX',
                                'alignY',
                                'content',
                                'html',
                                'rendering',
                                'hitArea',
                            ]);
                        });
                        Object.keys( shape.data ).forEach( key => {
                            dataItems[ key ] =  pick( shape.data[ key ], [
                                'value',
                            ]);
                        });


                        // persist currentShape data
                        shape.switchHistory[ currShapeDefKey ] = {
                            texts: textData,
                            width: shape.width,
                            height: shape.height,
                            scaleX: shape.scaleX,
                            scaleY: shape.scaleY,
                            data: dataItems,
                            images: cloneDeep( shape.images ),
                        };

                        // In created hook new text models are created and added to shape.texts
                        ( shape as any ).__textsCached = shape.texts;
                        delete shape.texts;

                        shape.images = {};

                        if ( this.shapeSpecificDataMap[ shape.defId ]) {
                            this.shapeSpecificDataMap[ shape.defId ].forEach( key => {
                                if ( shape.data[ key ]) {
                                    delete shape.data[ key ];
                                }
                            });
                        }

                        if ( def.logicClass ) {
                            return this.defLocator.getClass( def.logicClass ).pipe(
                                tap(( logicClass: any ) => {
                                    const instance = LogicClassFactory.instance.create( logicClass );
                                    const cacheData = shape.data;
                                    if ( instance.created ) {
                                        shape.data = {};
                                        ( instance as any ).created( shape, def, this.changeModel );
                                        Object.assign( shape.data, cacheData );
                                    }
                                }),
                            );
                        }
                        return of( def );
                    }),
                ];
                return forkJoin( ...obs ).pipe( mapTo( def ));
            }),
            tap( defn => {
                def = cloneDeep( defn );
                if ( !this.resultData ) {
                    this.resultData = {};
                }
                this.resultData.shapes = {};
                this.data.shapeIds.forEach( id => {
                    const shape: ShapeModel = this.changeModel.shapes[id] as any;
                    const nextShapeDefKey = this.data.newDefId.replace( /\./g, '_' ) + '_' + this.data.newVersion;
                    const curretDefMultiText = Object.keys(( shape as any ).__textsCached || {}).length > 1;
                    const dontPreserveStyles = this.dontPreserveStyles.includes( shape.defId );
                    if ( dontPreserveStyles ) {
                        Object.assign( shape.style, def.style );
                    }

                    shape.defId = this.data.newDefId;
                    shape.version = def.version;

                    let textsFromDef = def.texts ? def.texts : {};
                    textsFromDef = merge( textsFromDef, shape.texts || {});

                    // Switching back to a previous shape
                    if ( shape.switchHistory[ nextShapeDefKey ]) {
                        textsFromDef = merge(
                            shape.switchHistory[ nextShapeDefKey ].texts,
                        );
                    }

                    shape.texts = Object.assign({}, textsFromDef );
                    const currentPrimaryText: any = Object.values(( shape as any ).__textsCached )
                        .find(( v: any ) => v.primary );

                    delete ( shape as any ).__textsCached;
                    shape.ports = shape.ports ? def.ports : [];

                    this.applyScale( nextShapeDefKey, shape, def );
                    this.applyDataItems( nextShapeDefKey, shape, def );
                    this.applyImages( nextShapeDefKey, shape, def );

                    // Update primary text
                    const _primaryText: any = Object.keys( textsFromDef )
                        .find( key =>  {
                            textsFromDef[ key ].id = key;
                            return textsFromDef[ key ].primary;
                        });
                    if ( _primaryText && currentPrimaryText ) {
                        const preserveAlign = shape.texts[ _primaryText ].content?.[ 0 ]?.align || 'center';
                        if ( !shape.texts[ _primaryText ].rendering || shape.texts[ _primaryText ].rendering === 'carota' ) {
                            const textColorFromDef = shape.texts[ _primaryText ].content[ 0 ].color;
                            shape.texts[ _primaryText ].content = cloneDeep( currentPrimaryText.content );
                            shape.texts[ _primaryText ].content.forEach(( c, i ) => {
                                c.align = preserveAlign;
                                if ( dontPreserveStyles ) {
                                    c.color = textColorFromDef;
                                }
                            });
                        } else {
                            shape.texts[ _primaryText ].html = shape.texts[ _primaryText ].html;
                        }

                        if ( _primaryText !== currentPrimaryText.id ) {
                            // Note: Have to update the shape text editor text
                            TiptapDocumentsManagerShapeText
                                .updateTiptpChildEditorNode(
                                    this.changeModel.id, shape.id, shape.texts[ _primaryText ], true );
                        }

                        const isPositionable = TextPostion
                            .getTextPositionString(
                                Object.assign({ xType: 'relative', yType: 'relative' },
                                shape.texts[ _primaryText ])) !== 'none';
                        const isCurrentPositionable = !curretDefMultiText && TextPostion
                            .getTextPositionString(
                                Object.assign({ xType: 'relative', yType: 'relative' },
                                currentPrimaryText )) !== 'none';
                        // Position should be preserved for positionalble primary texts
                        // E.g If the shape text posiiton is set to bottom-outside, it should be preserved
                        // if the new shape is also positionable.
                        if ( isCurrentPositionable && Object.keys( shape.texts ).length === 1 && isPositionable ) {
                            shape.texts[ _primaryText ].x = currentPrimaryText.x;
                            shape.texts[ _primaryText ].y = currentPrimaryText.y;
                            shape.texts[ _primaryText ].angle = currentPrimaryText.angle;
                            shape.texts[ _primaryText ].xType = currentPrimaryText.xType;
                            shape.texts[ _primaryText ].yType = currentPrimaryText.yType;
                            shape.texts[ _primaryText ].alignY = currentPrimaryText.alignY;
                            shape.texts[ _primaryText ]._alignX = currentPrimaryText._alignX;
                            shape.texts[ _primaryText ].positionString = currentPrimaryText.positionString;
                        }
                        shape.texts[ _primaryText ].width = currentPrimaryText.width;
                        shape.texts[ _primaryText ].height = currentPrimaryText.height;
                    }
                    this.resultData.shapes[ id ] = shape;
                });
            }),
        );
    }

    /**
     * Return true if the new shape should be scaled to fit the old shape
     */
    protected applyScale( nextShapeDefKey, shape, def ) {
        // For complex shapes - which has multiple texts, or has vectors we apply scale from cache
            // If no cache, we apply scale from current shape
        // For simple shapes - keep the scale as it is
        if ( shape.texts && Object.keys( shape.texts ).length > 1 ) {
            if ( this.shapesToResetScale.includes( shape.defId )) {
                shape.scaleX = 1;
                shape.scaleY = 1;
            } else if ( shape.switchHistory[ nextShapeDefKey ]) {
                shape.scaleX = shape.switchHistory[ nextShapeDefKey ].scaleX;
                shape.scaleY = shape.switchHistory[ nextShapeDefKey ].scaleY;
            }
        } else {

            const hScale = def.transformSettings?.hScale !== undefined ?
                def.transformSettings.hScale : true;
            const vScale = def.transformSettings?.vScale !== undefined ?
                def.transformSettings.vScale : true;

            if ( hScale ) {
                const scaleX = Math.max( shape.width, def.defaultBounds.width ) / def.defaultBounds.width;
                shape.scaleX = scaleX;
            }

            if ( vScale ) {
                const scaleY = Math.max( shape.height, def.defaultBounds.height ) / def.defaultBounds.height;
                shape.scaleY = scaleY;
                // Update the sclae for fixed aspect ratio
                if ( def.transformSettings?.fixAspectRatio ) {
                    shape.scaleY = shape.scaleX;
                }
            }
        }
        Object.assign( shape.defaultBounds, def.defaultBounds );
    }

    protected applyDataItems( nextShapeDefKey, shape, def ) {
        const data = {};
        Object.keys( def.dataDef || {}).forEach( key => {
            if ( key === 'people' ) {
                  data[ key ] = { value: {
                    people: [],
                    source: {
                      id: 'collabs',
                      name: 'People',
                    },
                  }};
            } else {
                data[ key ] = { value: def.dataDef[ key ].value };
            }
        });
        if ( shape.switchHistory[ nextShapeDefKey ]?.data ) {
            Object.assign( data, shape.switchHistory[ nextShapeDefKey ].data );
        }
        Object.assign( shape.data, data );
    }

    protected applyImages( nextShapeDefKey, shape, def ) {
        const images = cloneDeep( def.images || {});
        if ( shape.switchHistory[ nextShapeDefKey ]?.images ) {
            Object.assign( images, shape.switchHistory[ nextShapeDefKey ].images );
        }
        Object.assign( shape.images, images );
    }

}

Object.defineProperty( SwitchShapeAny, 'name', {
    value: 'SwitchShapeAny',
});
