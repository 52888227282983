import { AbstractConnector } from './connector-abstract';

/**
 * This class contains the capability to draw a straight
 * connector using the coordinate data stored into the model,
 * using the provided graphics object.
 *
 * To draw a straight connector, the model should have
 * a path with each point the connector should connect to.
 *
 * @author  Nkweti Awa
 * @since   2022-05-12
 */
export class ConnectorDivided extends AbstractConnector {

    /**
     * Draws the straight connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }
        // start from the head point
        this.moveTo( points[0]);
        // connect all other points
        const firstPoint =  points[ 0 ];
        const lastPoint = points[ points.length - 1 ];
        const totalX = lastPoint.x - firstPoint.x;
        const totalY = lastPoint.y - firstPoint.y;
        // const length = Math.sqrt( totalX * totalX + totalY * totalY );

        this.lineTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 });
        this.moveTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 });
        this.lineTo( lastPoint );
        this.graphics.setStrokeDash([]);
        this.drawDivider( this.model.name, firstPoint, totalX, totalY );
    }

    // Draws a divider in the middle of the connector based on the name
    public drawDivider( name: string, firstPoint: any, totalX: number, totalY: number ) {
        switch ( name ) {
            case 'Estranged':
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 + 15 });
                this.moveTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 + 15 });
                break;
            case 'Divorced':
                this.moveTo({ x: firstPoint.x + totalX / 2 + 5, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 20, y: firstPoint.y + totalY / 2 + 15 });
                this.moveTo({ x: firstPoint.x + totalX / 2 + 20, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 5, y: firstPoint.y + totalY / 2 + 15 });
                break;
            case 'Separated':
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 + 15 });
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 });
                break;
            case 'Manipulative':
            case 'Widowed':
                this.moveTo({ x: firstPoint.x + totalX / 2 - 15, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 15, y: firstPoint.y + totalY / 2 + 15 });
                this.moveTo({ x: firstPoint.x + totalX / 2 + 15, y: firstPoint.y + totalY / 2 - 15 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 15, y: firstPoint.y + totalY / 2 + 15 });
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 });
                break;
            case 'Cohabitation':
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 + 10 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2  });
                this.lineTo({ x: firstPoint.x + totalX / 2, y: firstPoint.y + totalY / 2  - 10 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2  });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2  + 10 });
                this.lineTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2  + 10 });
                break;
            case 'Love':
            case 'Admire':
                this.graphics.drawEllipse( firstPoint.x + totalX / 2 - 10, firstPoint.y - 10 + totalY / 2, 20, 20 );
                break;
            case 'Controling':
                this.graphics.drawRect( firstPoint.x + totalX / 2 - 10, firstPoint.y - 10 + totalY / 2, 20, 20 );
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y - 10 + totalY / 2 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + 10 + totalY / 2 });
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + 10 + totalY / 2 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y - 10 + totalY / 2 });
                break;
            case 'In Love':
                this.graphics.drawEllipse( firstPoint.x + totalX / 2 - 10, firstPoint.y - 6 + totalY / 2, 12, 12 );
                this.graphics.drawEllipse( firstPoint.x + totalX / 2 - 2, firstPoint.y - 6 + totalY / 2, 12, 12 );
                break;
            default:
                this.moveTo({ x: firstPoint.x + totalX / 2 - 10, y: firstPoint.y + totalY / 2 });
                this.lineTo({ x: firstPoint.x + totalX / 2 + 10, y: firstPoint.y + totalY / 2 });
                break;
        }
    }
}

Object.defineProperty( ConnectorDivided, 'name', {
    value: 'ConnectorDivided',
});
