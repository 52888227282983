import { IPoint2D } from 'flux-definition/src';
import { AbstractConnector } from './connector-abstract';

/**
 * This class contains the capability to draw a wavy
 * connector using the coordinate data stored into the model,
 * using the provided graphics object.
 *
 * To draw a wavy connector, the model should have
 * a path with each point the connector should connect to.
 *
 * @author  Nkweti Awa
 * @since   2022-05-11
 */
export class ConnectorDouble extends AbstractConnector {

    /**
     * Draws the wavy connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        if ( !points.length ) {
            return;
        }

        const firstPoint =  points[ 0 ];
        const lastPoint = points[ points.length - 1 ];

        this.moveTo({ x: firstPoint.x, y: firstPoint.y - 5 });
        this.lineTo({ x: lastPoint.x, y: lastPoint.y - 5 });

        this.moveTo({ x: firstPoint.x, y: firstPoint.y + 5 });
        this.lineTo({ x: lastPoint.x, y: lastPoint.y + 5 });

        this.drawExtras( this.model.name, firstPoint, lastPoint );
    }

    /**
     * draw any extra designs we have on the connector
     * @param name the connector's name
     * @param firstPoint
     * @param lastPoint
     */
    public drawExtras( name: string, firstPoint: IPoint2D, lastPoint: IPoint2D ) {
        switch ( name ) {
            case 'Best Friends':
                if ( firstPoint.y !== lastPoint.y ) {
                    break;
                }
                const points = [ firstPoint.x, lastPoint.x ].sort(( a, b ) => a - b );
                this.moveTo({ x: points[0], y: firstPoint.y });
                for ( let i = points[0] + 10; i <= points[1]; i = i + 20 ) {
                    this.moveTo({ x: i, y: firstPoint.y - 5 });
                    this.lineTo({ x: i - 10, y: firstPoint.y + 5 });
                }
                break;
            default:
        }
    }

}

Object.defineProperty( ConnectorDouble, 'name', {
    value: 'ConnectorDouble',
});
