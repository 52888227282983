import {
    AfterViewInit, ChangeDetectionStrategy, Component,
    ComponentRef, Input, OnDestroy, OnInit, ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Sakota } from '@creately/sakota';
import { TranslateService } from '@ngx-translate/core';
import { EDataCommandEvent } from 'apps/nucleus/src/base/edata/command/edata-command-event';
import { EDataRegistry } from 'apps/nucleus/src/base/edata/edata-registry.svc';
import { EDataModel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { EntityListModel } from 'apps/nucleus/src/base/edata/model/entity-list.mdl';
import { EDataManage } from 'apps/nucleus/src/framework/edata/edata-manage.svc';
import {
    ATree, CollapsibleMenu, CollapsibleMenuItem, CommandService, DialogBoxController,
    ICollasibleItemOption, ITreeNode, Logger, ModalController,
    StateService, Tracker, AbstractNotification, NotificationType, NotifierController,
} from 'flux-core';
import { EntityLinkType, IDialogBoxData, IShapeDefinition, ShapeType } from 'flux-definition';
import { CollaboratorType, DiagramInfoLocator } from 'flux-diagram';
import { DataStore } from 'flux-store';
import { cloneDeep, forEach as _forEach } from 'lodash';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from '../../../../base/edata/locator/edata-locator-locator';
import { EntityModel } from '../../../../base/edata/model/entity.mdl';
import { DefinitionLocator } from '../../../../base/shape/definition/definition-locator.svc';
import { GitHubDataSource } from '../../data-sources/github.com/github-data-source.cmp';
import { ShapeManageService } from '../../../feature/shape-manage.svc';
import { ILibraryItem, LibraryPanel } from './../panels/library-panel.cmp';
import { CustomEntitySearchComponent } from './advance-search/custom-entity-search/custom-entity-search.cmp';
import { DataImportOption, DataImportWindow } from './data-import/data-import-window.cmp';
import { EDataLibraryGroup } from './edata-item/edata-library-group.cmp';
import { DynamicComponentService } from 'flux-core/src/ui';
import { DataSourceDomain } from '../../data-sources/model/data-source.model';
import { GoogleSheetDataImporter } from '../../../../base/edata/import/google-sheet-data-importer';

/**
 * This is a static library panel layout component which
 * will be inserted inside a collapsible menu item.
 *
 */
@Component({
    templateUrl: './edata-library-panel.cmp.html',
    selector: 'edata-library-panel',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './edata-library-panel.scss' ],
})
// tslint:disable:member-ordering
export class EDataLibraryPanel extends LibraryPanel implements OnInit, OnDestroy, AfterViewInit {

    public eDataModel: EDataModel;
    public eItems: Subject<Map<string, Observable<{[ id: string]: ILibraryItem}>>>;

    @Input()
    public dataSourcePanelContainer: ViewContainerRef;
    @ViewChild( 'entityGroups', { static: true })
    public collapsibleMenu: CollapsibleMenu;

    public isFolderOwner: boolean = false;

    public options: ICollasibleItemOption[] = [];

    public secondaryOptions: ICollasibleItemOption[] = [];

    public parent: CollapsibleMenuItem;

    public section: string;

    public selected: Function;
    /**
     * Emits whether the database item list is empty or not.
     */
    public isItemListEmpty: BehaviorSubject<boolean>;
    public showUpdatePanel: Subject<boolean> = new BehaviorSubject<boolean>( false );
    public updatingFromSource: Subject<boolean> = new BehaviorSubject<boolean>( false );
    public lastUpdateTime: Subject<String> = new BehaviorSubject<String>( 'n/a' );
    public smartSetsList: {[id: string]: EntityListModel };
    public searchResult: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    public expanded = false;

    public searchQuery: string;

    public checked: BehaviorSubject<boolean> = new BehaviorSubject( false );


    protected listeningStaticChanges: boolean = false;
    private eItemSubjects: { [id: string]: Subject<boolean> };
    private itemTree: { [id: string]: ATree<ILibraryItem> };
    private entityDefCacheMap = new Map();
    private entitiesModels: Map< string, EntityModel> = new Map();
    private smartSetClose: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>( false );
    private definitionCacheMap = new Map();

    constructor(
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any>,
        protected ell: EDataLocatorLocator,
        protected dll: DiagramLocatorLocator,
        protected commandService: CommandService,
        protected eDataManager: EDataManage,
        protected diagInfoLocator: DiagramInfoLocator,
        protected cs: CommandService,
        protected eDataManage: EDataManage,
        protected modalController: ModalController,
        protected translate: TranslateService,
        protected dialogBoxController: DialogBoxController,
        protected dataStore: DataStore,
        protected shapeManageService: ShapeManageService,
        protected notifierController: NotifierController,
        private dynamicComponentService: DynamicComponentService,
        private googleSheetDataImporter: GoogleSheetDataImporter,
    ) {
        super( state, cs, shapeManageService );
        this.eItemSubjects = {};
        this.eItems = new Subject( );
        this.isItemListEmpty = new BehaviorSubject<boolean>( false );
        this.trackingInfo = {
            location: 'EdataPanel',
        };
    }

    /**
     * Empty state message title
     */
    public get emptyStateTitle(): string {
        return 'EDATA_PANEL.EMPTY_DB.TITLE';
    }

    /**
     * Empty state message body
     */
    public get emptyStateBody(): string[] {
        return [ 'EDATA_PANEL.EMPTY_DB.BODY.LINE_1', 'EDATA_PANEL.EMPTY_DB.BODY.LINE_2' ];
    }

    /**
     * Empty state message icon
     */
    public get emptyStateIcon(): string {
        return './assets/images/task-panel/tasks-empty-state.svg';
    }

    /**
     * Title for floating tooltip item
     */
    public get tooltipTitle(): string {
        return this.translate.instant( 'EDATA_PANEL.TOOLTIP.DATA_IMPORT.TITLE' );
    }

    /**
     * Content for floating tooltip item
     */
    public get tooltipContent(): string {
        return this.translate.instant( 'EDATA_PANEL.TOOLTIP.DATA_IMPORT.CONTENT' );
    }

    public closeTooltip( checked: boolean ) {
        this.parent.hasFloatingTooltip = false;
        if ( checked ) {
            const tooltipSettings = localStorage.getItem( 'HIDDEN_TOOLTIPS' );
            if ( tooltipSettings && tooltipSettings !== null ) {
                localStorage.setItem( 'HIDDEN_TOOLTIPS',
                    JSON.stringify({ ...JSON.parse( tooltipSettings ), dataImport: true }));
            } else {
                localStorage.setItem( 'HIDDEN_TOOLTIPS',
                    JSON.stringify({ dataImport: true }));
            }
        }
    }

    public ngAfterViewInit() {
        // this.insertDataSourcePanel( this.eDataModel );
    }

    /**
     * On init.
     */
    public ngOnInit() {

        const eItems = new Map();
        this.itemTree = {};
        // sort function for the library items
        const sorter: Function = ( a: ILibraryItem, b: ILibraryItem ) => {
            if ( a.text && b.text && a.text.title.toUpperCase() > b.text.title.toUpperCase()) {
                return 1;
            }
            return -1;
        };

        this.showUpdatePanel.subscribe( show => {
            if ( show ) {
                this.parent.open();
            }
        });

        this.subs.push( this.ell.getEData( this.id ).pipe(
            switchMap( locator => locator.getEDataModel()),
            tap( mdl => {
                this.eDataModel = mdl;
                if ( mdl.lastUpdatedFromSource ) {
                    this.lastUpdateTime.next(( new Date( mdl.lastUpdatedFromSource )).toLocaleString());
                }
                this.updateOptions();
                if ( !this.eDataModel.isSharedWithTeam()) {
                    this.listenToStaticChanges();
                }
            }),
        ).subscribe(() => this.parent.detectChanges()),
        this.searchResult.subscribe( result => {
            const option = this.parent?.secondaryOptions.find( i => i.id === 'searchData' );
            if ( option?.icon === 'smart-filter' ) {
                this.parent.secondaryOptions.find( i => i.id === 'searchData' ).icon = result?.length > 0 ? 'smart-filter-filled' : 'smart-filter';
                if ( result?.length > 0 ) {
                    this.parent.open();
                }
            }
        }),
        this.state.changes( 'FocusOnDatabase' ).subscribe( result => {
            const icons = [ 'database', 'database-focus', 'database-new' ];
            if ( !icons.includes( this.parent.preTitleIcon )) {
                return;
            }
            if ( result && result === this._id ) {
                this.parent.preTitleIcon = 'database-focus';
                this.parent.open();
                try {
                    const hiddenTooltips = JSON.parse( localStorage.getItem( 'HIDDEN_TOOLTIPS' ));
                    if ( !hiddenTooltips.dataImport ) {
                        this.parent.hasFloatingTooltip = true;
                        setTimeout(() => this.parent.floatingTooltip.show(), 100 );
                    }
                } catch ( e ) {
                    this.parent.hasFloatingTooltip = true;
                    setTimeout(() => this.parent.floatingTooltip.show(), 100 );
                }
            } else {
                this.parent.preTitleIcon = 'database-new';
            }
            this.parent.detectChanges();
        }));


        const eItemSub = this.ell.getEData( this.id ).pipe(
            switchMap( locator =>
                locator.getEDataChangesWithCurrent().pipe(
                    filter( data => {
                        if ( data.source === 'init' ) {
                            return true;
                        }
                        const removeEntity = [ /entities\.[^\.]+$/, /entityLists\.[^\.]+$/ ];
                        const checks = [
                            /^entities$/,
                            /^entityLists$/,
                            /entities\.[^\.]+$/,
                            /entityLists\.[^\.]+$/,
                            /entities\.[^\.]+\.data.name/,
                            /entities\.[^\.]+\.data.title/,
                            /entityLists\.[^\.]+\.name/,
                            /entityLists\.[^\.]+\.entities/,
                            /entities\.[^\.]+\.data.identifier/,
                        ];
                        if ( data.model.isCustom ) {
                            checks.push( /entities\.[^\.]+\.texts/ );
                            checks.push( /customEntityDefs\.[^\.]+\.deleted/ );
                        }
                        if ( data.modifier ) {
                            if ( data.modifier.$set ) {
                               for ( const key of Object.keys( data.modifier.$set )) {
                                    if ( checks.some( regex => regex.test( key ))) {
                                        return true;
                                    }
                                }
                            }
                            if ( data.modifier.$unset ) {
                                for ( const key of Object.keys( data.modifier.$unset )) {
                                    if ( removeEntity.some( regex => regex.test( key ))) {
                                        return true;
                                    }
                                }
                            }
                        }
                        return false;
                    }),
                    switchMap( model => {
                        const groups = {};
                        this.smartSetsList =  model.model.getActiveEntityLists();
                        _forEach( model.model.getActiveEntities(), ent => {
                            this.entitiesModels.set( ent.id, ent );
                            const def = ent.getShapeDefIdForContext();
                            let entityItem;
                            if ( def ) {
                                if ( this.definitionCacheMap.has( def.id )) {
                                    const definition = this.definitionCacheMap.get( def.id );
                                    entityItem = of({ eDataId: this.id, entity: ent, def: definition });
                                } else {
                                    entityItem = this.defLocator.getDefinition( def.id ).pipe(
                                        switchMap( val => {
                                            this.definitionCacheMap.set( def.id, val );
                                            return of({ eDataId: this.id, entity: ent, def: val });
                                        }),
                                    );
                                }
                                const group = ent.getDef().grouping;
                                if ( group ) {
                                    if ( !groups[group]) {
                                        groups[group] = [];
                                    }
                                    groups[group].push( entityItem );
                                }
                            } else {
                                Logger.debug( 'ShapeDefID not loaded for edata ', this.id );
                            }
                        });

                        // remove from eItems where grouping does not match
                        // helps get rid of the last remaining item
                        for ( const [ key ] of eItems ) {
                            if ( groups[key]) {
                                continue;
                            }
                            eItems.delete( key );
                        }

                        for ( const key in groups ) {
                            const o = forkJoin( groups[ key ]).pipe(
                                map( res => {
                                    const tree = new ATree<ILibraryItem>();
                                    for ( const id in res ) {
                                        const item: any = res[id];
                                        const libItem = this.createLibraryItem( item.eDataId, item.entity, item.def );
                                        tree.addItem( libItem.itemId, libItem, libItem.parentId );
                                    }
                                    const retVals = tree.toArray( sorter, [ 'indent', 1 ]);
                                    this.itemTree[ key ] = tree;
                                    return retVals as any;
                                }),
                            );
                            eItems.set( key, o );
                        }
                        setTimeout(() => {
                            this.eItems.next( eItems );
                            this.isItemListEmpty.next( eItems.size === 0 );
                        }, 0 );
                        return of({});
                    }),
                ),
            ),
        ).subscribe();

        this.subs.push( eItemSub );
        this.addEDataLibraryGroup();
    }

    public toggleSubMenu() {
        this.expanded = !this.expanded;
    }

    public insertDataSourcePanel ( eModel: EDataModel  ) {
        if ( this.searchResult.value?.length ) {
            this.emptyResult();
            return;
        }
        const type = this.getDataSourcePanel( eModel.defId );
        if ( type ) {
            this.dataSourcePanelContainer.clear();
            const dataSourcePanel: ComponentRef<any> = this.dynamicComponentService.makeComponent( type );
            dataSourcePanel.instance.searchResult = this.searchResult;
            dataSourcePanel.instance.eDataModel = eModel;
            dataSourcePanel.instance.closed.subscribe(() => {
                this.dataSourcePanelContainer.clear();
            });
            dataSourcePanel.instance.querySubject.subscribe( query => {
                this.searchQuery = query;
            });
            this.dynamicComponentService.insert( this.dataSourcePanelContainer, dataSourcePanel );
            dataSourcePanel.changeDetectorRef.detectChanges();
            this.selected();
        }
    }

    public handleSmartSetNameChange( name: string, data: EntityListModel ) {
        const proxied = Sakota.create(  this.eDataModel );
        proxied.entityLists[ data.id ].name = name;
        const modifier = proxied.__sakota__.getChanges();
        this.commandService.dispatch( EDataCommandEvent.applyModifierEData,
            this.eDataModel.id,
            { modifier });
    }
    public handleSmartSetDeleteChange( data: EntityListModel ) {
        this.eDataManager.removeEntityList( this.eDataModel, data.id, this.eDataModel.name, data.name );
    }

    public handleSmartSetDragStart( event: DragEvent, data: EntityListModel ) {
        const shapes = {};
        const copyDataFromEntity = ( entity, entityDef?: any ) => {
            if ( !entityDef ) {
                entityDef = entity.getDef();
            }
            Object.keys( shapes[entity.id].data ).forEach(( key: string ) => {
                if ( entityDef && entityDef.dataItems[key]) {
                    shapes[entity.id].data[key].value = entity.data[key] ||
                    entityDef.dataItems[key].default;
                }
            });
        };
        for ( const entityId of data.entities ) {
            const entity = this.entitiesModels.get( entityId );
            if ( entity ) {
                const def = entity.getShapeDefIdForContext();
                const shapeDef =  this.definitionCacheMap.get( def.id );
                if ( def ) {
                    shapes[entityId] = Object.assign({}, shapeDef );
                    const shapeData  = cloneDeep( shapeDef.data );
                    shapes[entityId].data = {};
                    if ( entity.style ) {
                        shapes[entityId].style =  entity.style.shape;
                    }
                    Object.assign( shapes[entityId].data, shapeData );
                    Object.assign( shapes[entityId].data, cloneDeep( shapeDef.dataDef ));

                    if ( EDataRegistry.customEdataDefId ===  entity.defId ) {
                        copyDataFromEntity( entity );
                    } else {
                        const entityDef = entity.getDef();
                        if ( entityDef.shapeDefs[shapes[entityId].defId]) {
                            const dataMap = entityDef.shapeDefs[shapes[entityId].defId].dataMap;
                            dataMap.forEach(({ dataItemId, eDataFieldId }) => {
                                shapes[entityId].data[dataItemId].value = entity.data[eDataFieldId] ||
                                     entityDef.dataItems[dataItemId].default;
                            });
                        } else {
                            copyDataFromEntity( entity, entityDef );
                        }
                    }

                    shapes[entityId].entityListId = data.id;
                    shapes[entityId].triggerNewEData = true;
                    if ( entity ) {
                        shapes[entityId].eData = {
                            [this.id]: entity?.id,
                        };
                    }

                    if ( entity.texts && entity.texts.length > 0 &&
                        entity.texts.some( t => t.shapeDef === shapeDef.defId )) {
                        const texts = ( shapeDef as any ).texts || {};
                        const primaryTextId = Object.keys( texts ).find( txtId => texts[txtId].primary );
                        if ( primaryTextId ) {
                            const text = entity.texts.find( t => t.shapeDef === shapeDef.defId ).text;
                            shapes[entityId].texts = {
                                [ primaryTextId]: text,
                            };
                        }
                    }

                    if ( entity.textStyles ) {
                        shapes[entityId].textStyle = Object.assign({}, entity.textStyles );
                    }
                }
            }
        }
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData( 'PreDifQueries',
         JSON.stringify({
             definedSearchQuery: data.search,
             shapes: shapes,
         }));
    }

    /**
     * Destory component.
     */
    public ngOnDestroy() {
        while ( this.subs.length ) {
            this.subs.pop().unsubscribe();
        }
        this.eItems.complete();
        _forEach( this.eItemSubjects, v => {
            v?.complete();
        });
        this.eItemSubjects = null;
    }

    /**
     * Handle drag and drop start.
     * @param event
     * @param data
     */
    public handleDragStart( event: any, data: any ) {
        data.triggerNewEData = true;
        if ( data.texts ) {
            // Clearing the default text coming fro the def but keep the formatting
            Object.keys(  data.texts ).forEach( id => {
                const text = data.texts[ id ];
                text.content.forEach( c => c.text = '\n' );
            });
        }
        if ( data.drawCode ) {
            data.type = ShapeType.Freehand;
        }
        super.handleDragStart( event, data );
    }

    public handleSmartSetClose( event: boolean ) {
        this.smartSetClose.next( event );
    }

    public emptyResult() {
        this.searchResult.next([]);
        this.dataSourcePanelContainer.remove();
    }

    /**
     * Add libraries.
     */
    protected addEDataLibraryGroup() {

        this.subs.push(
            this.eItems.pipe(
                tap( items => {
                    for ( const [ key, val ] of items ) {

                        if ( !this.collapsibleMenu.has( key )) {
                            const edataLibraryGroupInfo = {
                                id: key,
                                label: key,
                                isCustom: this.eDataModel?.isCustom,
                                eDataName: this.eDataModel?.name,
                                eDataId: this.id,
                                entityDefId: val.pipe( map( v => v[0]?.data.entityDefId )),
                                entities: new BehaviorSubject({}),
                                title: key,
                                titleEditable: true,
                                handleDragStart: this.handleDragStart.bind( this ),
                            };
                            val.subscribe( v => edataLibraryGroupInfo.entities.next( v ));
                            this.collapsibleMenu.add(
                                CollapsibleMenuItem,
                                EDataLibraryGroup,
                                edataLibraryGroupInfo,
                                false,
                                { _isOpen: false, preTitleIcon: 'data-object' },
                            ).subscribe();
                        } else {
                            val.subscribe( v =>
                                ( this.collapsibleMenu.getMenuItemComponent( key ).menuItem as EDataLibraryGroup )
                                                                                                .entities.next( v ));
                        }
                    }
                    this.collapsibleMenu.panels
                            .map( panel => panel.id )
                            .filter( id => !items.get( id ))
                            .forEach( id => this.collapsibleMenu.getMenuItemComponent( id ).removeChild.emit());
                }),
            ).subscribe(),
        );
    }

    /**
     * Returns the ILibraryItem needed for rendering canvas based thumbnail items
     */
    protected createLibraryItem( eDataId: string, entity: EntityModel, def: IShapeDefinition ): ILibraryItem {

        // we always create a new def for each entity
        const newDef = Object.assign({}, def );
        newDef.eData = {};
        newDef.entityDefId = entity.eDefId;
        newDef.eData[ eDataId ] = entity.id;
        let eDataDef;
        if ( !this.entityDefCacheMap.has( entity.id )) {
            eDataDef = entity.getDef();
            this.entityDefCacheMap.set( entity.id, eDataDef );
        } else {
            eDataDef = this.entityDefCacheMap.get( entity.id );
        }
        let title = def.name;
        if ( entity.data && entity.data[eDataDef.titleDataItem]) {
            title = entity.data[eDataDef.titleDataItem];
        } else {
            const t = entity.getPrimaryText();
            if ( t ) {
                title = t;
            } else {
                // FIXME: Packages don't have data property of entity
                Logger.debug( 'EDataLibraryPanel.createLibraryItem FAILED ', entity );
            }
        }

        if ( !newDef.texts ) {
            const textData = entity.texts.find( t => t.shapeDef === def.defId );
            if ( textData && textData.text ) {
                newDef.texts = { [textData.text.id]: textData.text };
            }
        }

        const entityCount = this.getEntityCountPerDoc( entity );
        const libItem = this.createEntityLibraryItem( newDef,  title, entityCount );
        libItem.icon = eDataDef.icon;

        if ( entity.links ) { // for the tree
            const children = [];
            Object.values( entity.links ).forEach( link => {
                if ( link.eDataId === eDataId ) {
                    if ( link.type === EntityLinkType.PARENT ) {
                        libItem.parentId = link.entityId;
                    } else if ( link.type === EntityLinkType.CHILD ) {
                        children.push( link.entityId );
                        libItem.childId = link.entityId;
                    }
                }
            });
            libItem.children = children;
        }

        libItem.isClosed = new Subject();
        this.eItemSubjects[ entity.id ] = libItem.isClosed;
        libItem.itemId = entity.id;
        libItem.indent = 0;
        ( libItem.data as any ).sidebarEntity = entity;
        if ( eDataDef.defaultShape ) {
            ( libItem.data as any ).drawCode = eDataDef.defaultShape.drawCode;
            ( libItem.data as any ).textStyle = eDataDef.defaultShape.texts;
        }
        libItem.grouping = entity.getDef().grouping;
        libItem.refChangeCount = entity.refChangeCount;

        return libItem;
    }

    /**
     * Listen to collbsible menu item open changes and trigger tracking event.
     */
    protected listenCollapMenuItemOpen() {
        this.subs.push( this.parent.toggleChild.pipe( delay( 500 )).subscribe(() => {
            if ( this.parent.active ) {
                Tracker.track( `dialog.database.${this.section}.click` );
            }
        }));
    }

    protected handleGroupClose( event: boolean, group: string ) {
        if ( group && this.itemTree[ group ]) {
            const nodes = this.itemTree[ group ].getRootNodes();
            nodes.forEach( node => {
                node.value.isClosed.next( event );
                this.updateChildren( node, event );
            });
        }
    }

    protected handleClose( event: boolean, data: ILibraryItem ) {
        if ( data && data.children && this.itemTree && data.grouping ) {
            const node = this.itemTree[data.grouping].getNode( data.itemId );
            this.updateChildren( node, event );
        }
    }

    protected handleMouseOut(): Observable<unknown> {
        this.isMouseOverPopover();
        return of();
    }

    /**
     * Function to set the hover bool in parent
     */
    protected isMouseOverPopover() {
        const popoverElmt = document.getElementById( 'preview-popover' );
        popoverElmt.addEventListener( 'mouseleave', event => {
            this.isMouseHover = false;
        }, false );
        popoverElmt.addEventListener( 'mouseover', event => {
            this.isMouseHover = true;
        }, false );
    }

    protected getDataSourcePanel( defId: string ) {
        const panelEdataDefMap =  {
            'creately.edata.datasource.github': GitHubDataSource,
            'creately.edata.custom': CustomEntitySearchComponent,
        };
        if ( panelEdataDefMap[ defId ]) {
            return panelEdataDefMap[ defId ];
        }
        return CustomEntitySearchComponent;
    }

    /**
     * Update options baed on the current updated eDataModel.
     */
    protected updateOptions() {

        const options: ICollasibleItemOption[] = [];
        const secondaryOptions: ICollasibleItemOption[] = [];
        if ( this.eDataModel.isCustom ) {
            options.push({
                id: 'rename',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.RENAME' ),
                // icon: 'rename',
                callback: () => {
                    this.parent.titleEditable.next( true );
                    Tracker.track( 'database.settings.click',
                                    { value1Type: 'property-click', value1: 'rename' });
                },
            });
            if ( !this.eDataModel.isSharedWithTeam()) {
                options.push({
                    id: 'teamShare',
                    label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.TEAM_SHARE' ),
                    // icon: 'organination',
                    callback: () => {
                        this.shareWithTeam( this.eDataModel.id, CollaboratorType.EDITOR );
                        Tracker.track( 'database.settings.click',
                                        { value1Type: 'property-click', value1: 'teamShare' });
                    },
                });
            }
        }
        if ( this.eDataModel.defId !== GitHubDataSource.eDataDefId ) {
            options.push({
                id: 'importData',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.IMPORT_DATA' ),
                // icon: 'import',
                callback: () => {
                    this.modalController.show( DataImportWindow, {
                        inputs: {
                            importingInto: this.eDataModel,
                            dataImportOption: DataImportOption.CSV,
                        },
                    });
                    Tracker.track( 'database.settings.click',
                                    { value1Type: 'property-click', value1: 'importData' });
                },
            });
        }
        if ( this.eDataModel.dataSource /* && this.eDataModel.dataSource.domain === DataSourceDomain.GoogleSheets */ ) {
            options.push({
                id: 'refreshData',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.UPDATE' ),
                // icon: 'update',
                callback: () => {
                    this.showUpdatePanel.next( true );
                    this.updateFromSource();
                },
            });
        }
        if ( this.isFolderOwner ) {
            options.push({
                id: 'remove',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.DELETE' ),
                // icon: 'delete',
                callback: () => {
                    this.eDataManage.deleteDatabase( this.eDataModel.id ).subscribe();
                    Tracker.track( 'database.settings.click',
                                    { value1Type: 'property-click', value1: 'remove' });
                },
            });
        } else {
            options.push({
                id: 'remove',
                label: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.DELETE' ),
                disabled: true,
                tooltip: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.PERMISSION_REQUIRED' ),
                callback: () => this.showPermissionError(),
            });
        }
        secondaryOptions.push({
            id: 'searchData',
            label: 'Search',
            icon: this.eDataModel.defId !== GitHubDataSource.eDataDefId ? 'smart-filter' : 'search-large',
            callback: () => {
                this.insertDataSourcePanel( this.eDataModel );
            },
        });
        if ( this.eDataModel.dataSource /* && this.eDataModel.dataSource.domain === DataSourceDomain.GoogleSheets */ ) {
            secondaryOptions.push({
                id: 'refreshData',
                label: 'Update',
                icon: 'update',
                tooltip: this.translate.instant( 'EDATA_PANEL.EDATA_OPTIONS.TOOLTIPS.UPDATE_FROM_GOOGLE_SHEETS' ),
                callback: () => {
                    this.showUpdatePanel.next( true );
                },
            });
        }
        this.secondaryOptions = secondaryOptions;
        this.options = options;
    }

    /**
     * Datasource panel options
     * @returns
     */
    protected getDataSourcePanelOptions( ) {
        const options: ICollasibleItemOption[] = [];
        options.push({
                id: 'logout',
                label: 'Logout',
                callback: () => {},
            });
        return options;
    }

    protected updateFromSource() {
        this.updatingFromSource.next( true );
        const updatingMsg = this.translate.instant( 'EDATA_PANEL.UPDATE_DATABASE.UPDATING' );
        const updatedMsg = this.translate.instant( 'EDATA_PANEL.UPDATE_DATABASE.UPDATED' );
        const failedMsg = this.translate.instant( 'EDATA_PANEL.UPDATE_DATABASE.FAILED' );

        this.state.set( 'AcknowledgementMessage', { open: true, message: updatingMsg, showLoader: true });
        if ( this.eDataModel.dataSource.domain === DataSourceDomain.GoogleSheets ) {
            this.googleSheetDataImporter.refreshData( this.eDataModel ).pipe(
                tap({
                    next: res => {
                        // show progress
                    },
                    complete: () => {
                        // show success message
                        this.commandService.dispatch( EDataCommandEvent.applyModifierEData, this.eDataModel.id, {
                            modifier: { $set: { lastUpdatedFromSource: Date.now() }},
                        });
                        this.lastUpdateTime.next(( new Date()).toLocaleString());
                        this.state.set( 'AcknowledgementMessage',
                        { open: true, message: updatedMsg, showLoader: false });
                        this.clearAckMessage();
                        this.updatingFromSource.next( false );
                    },
                    error: e => {
                        // TODO: Show error message
                        this.state.set( 'AcknowledgementMessage',
                        { open: true, message: failedMsg, showLoader: false });
                        this.clearAckMessage();
                        this.updatingFromSource.next( false );
                    },
                }),
            ).subscribe();
        }
    }

    /**
     * Share EData with team.
     * @param eDataId
     * @param role
     */
    protected shareWithTeam( eDataId: string, role: CollaboratorType.EDITOR | CollaboratorType.REVIEWER ) {
        const data: IDialogBoxData = {
            id: 'left-sb-link-database',
            heading: 'EDATA_PANEL.DIALOG_BOX.TEAM_SHARE.HEADING',
            description: 'EDATA_PANEL.DIALOG_BOX.TEAM_SHARE.DESCRIPTION',
            descriptionParams: {},
            type: 'warning',
            icon: 'circle-warning',
            buttons: [{
                type: 'cancel',
                text: 'BUTTONS.CANCEL',
                clickHandler: /* istanbul ignore next */() => {
                    Tracker.track( 'dialog.database.publicShare.click',
                                       { value1Type: 'button-click', value1: 'cancel' });
                },
            },
            {
                type: 'ok',
                text: 'EDATA_PANEL.DIALOG_BOX.TEAM_SHARE.SHARE',
                clickHandler: () => {
                    this.commandService.dispatch( EDataCommandEvent.shareEDataWithTeam, eDataId, { role });
                    Tracker.track( 'dialog.database.publicShare.click',
                                       { value1Type: 'button-click', value1: 'share' });
                },
            }],
        };
        this.dialogBoxController.showDialog( data );
        Tracker.track( 'dialog.database.publicShare.load' );
    }

    protected showPermissionError() {
        const options = {
            inputs: {
                heading: this.translate.instant( 'NOTIFICATIONS.EDATA.ERROR_DELETING_DATABASE.HEADING' ),
                description: this.translate.instant( 'NOTIFICATIONS.EDATA.ERROR_DELETING_DATABASE.DESCRIPTION' ),
                autoDismiss: true,
            },
        };
        this.notifierController.show(
            'errorDeletingDatabase', AbstractNotification, NotificationType.Error, options, false );
    }

    /**
     * Listens to static changes and Update preTitleIcon value and options when the TeamAccess is updated.
     */
    protected listenToStaticChanges() {

        // This should execute once after options are initialized.
        if ( this.listeningStaticChanges ) {
            return;
        }
        this.listeningStaticChanges = true;
        this.subs.push( this.dataStore.findOneRaw( EDataModel, { id: this.id }).pipe(
            filter( eData => !!( eData?.teamAccess )),
            map( eData => eData?.teamAccess ),
        ).subscribe( teamAccess => {
            if ( !!teamAccess?.id ) {
                const index = this.options.findIndex( opt => opt.id === 'teamShare' );
                if ( index >= 0 ) {
                    this.options.splice( index, 1 );
                }
                this.parent.preTitleIcon = 'organination';
                this.parent.detectChanges();
            }
        }));
    }

    /**
     * Creates a library item for a simple shape
     */
    private createEntityLibraryItem( def: IShapeDefinition, title: string, counts?: any ): ILibraryItem {
        return {
            id: def.defId,
            text: {
                title: title,
                shapeCount: counts,
            },
            popoverCssClass: 'lib-preview-container',
            type: 'shape',
            data: def,
            thumbnailType: 'text',
            canvasIdPrefix: 'thumb-search',
            children: [],
        };
    }

    private updateChildren( node: ITreeNode<ILibraryItem>, isClosed: boolean ) {
        if ( node && node.children ) {
            node.children.forEach( cNode => {
                cNode.value.isClosed.next( isClosed );
                this.updateChildren( cNode, isClosed );
            });
        }
    }

     /**
      * Gets the number of shapes in a entity by type per document
      * @param entity
      */
    private getEntityCountPerDoc( entity: EntityModel ): { [ docId: string]: number } {
        const entityPerDoc = {};
        for ( const docId in entity.shapes ) {
            entityPerDoc[ docId ] = Object.keys( entity.shapes[ docId ]).length;
        }
        return entityPerDoc;
    }

    private clearAckMessage( timeout = 5000 ) {
        setTimeout(() => {
            this.state.set( 'AcknowledgementMessage', { open: false });
        }, timeout );
    }
}

/**
 * Interface for the shape search result
 * This format will be used in the state ShapeSearchResults
 */
export interface IShapeSearchResultSummary {

    /**
     * DefId and version will be used to get the shape defs
     */
    results: [{ defId: string, version: number }];

    /**
     * search text user will be entering
     */
    searchQuery: string;
// tslint:disable-next-line:max-file-line-count
}
