import { environment } from '../../../config/config';
import { uniq, uniqBy } from 'lodash';
import { Injectable } from '@angular/core';
import { CUSTOM_SHAPE_LIB_ID_PREFIX } from '../../library/abstract-shape-library';

/**
 * Interface for a library list item
 * used by the {@link LibraryList} component.
 */
export interface ILibraryListItem {
    /**
     * Library ID.
     */
    id: string;

    /**
     * The name of the library thats visible to the user.
     */
    label: string;

    /**
     * Categories that the library belongs to. A library can belong
     * to multiple categories.
     */
    groups: string[];

    /**
     * FAB display group
     */
    libGroup: string;

    /**
     * Library browser categories
     */
    category: string;

    /**
     * This is to specify top shapes to switch to in this library
     * ie most used shapes in the library with switch preferences
     */
    switchDefaults?: {
        defaultPreferences: any,
        shapes: {
            id: string,
            defId: string,
            data: any,
            defaultPreferences: any,
        },
    };
}

export interface ILibraryCategoryDefinition {

    /**
     * Identifies the category
     */
    id: string;

    /**
     * Name of the category
     */
    label: string;

    /**
     * A short description of the library category
     */
    description: string;

    /**
     * Descriptions for groups within the category
     */
    groups?: {[ groupId: string ]: { groupId?: string, description?: string, link?: string }};

    /**
     * Link to article explaining more about the library category and the libraries/groups within
     */
    link?: string;

    /**
     * Path to graphical representation of the library category, svg
     */
    graphic?: string;
}

/**
 * The library list class is used to manage the library list and all
 * the libraries that are available for the user is stored in this class.
 * There are several methods available that allows you to retrieve the desired
 * data related to a single library or a group of libraries.
 */
@Injectable()
export class LibraryList {

    /**
     * Array containing all the available libraries in Nucleus
     * which the users can add from the add library menu.
     */
    protected allShapeLibraries: Array<ILibraryListItem> = [
        { id: 'common-shapes', label: 'Shapes', groups: [], libGroup: 'Shapes', category: 'Basics' },
        { id: 'reaction', label: 'Reactions', groups: [], libGroup: 'Shapes', category: 'Basics' },
        { id: 'projects', label: 'Project Management', groups: [ 'Projects ' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'people', label: 'Human Resource Management', groups: [ 'Human Resources' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'arrows', label: 'Arrows', groups: [], libGroup: 'Shapes', category: 'Basics' },
        { id: 'archimate-application', label: 'Archimate Application', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-business', label: 'Archimate Business', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-composite', label: 'Archimate Composite', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-implementation', label: 'Archimate Implementation', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-motivation', label: 'Archimate Motivation', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-physical', label: 'Archimate Physical', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-strategy', label: 'Archimate Strategy', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'archimate-technology', label: 'Archimate Technology', groups: [ 'Archimate' ],
            libGroup: 'Archimate', category: 'Technical' },
        { id: 'creately-aws-a', label: 'AWS Analytics', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-app-integration', label: 'AWS App Integration', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-ar-vr', label: 'AWS AR & VR', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-blockchain', label: 'AWS Blockchain', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-business-applications', label: 'AWS Business Applications', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-cdn', label: 'AWS CDN', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-compute', label: 'AWS Compute', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-cost-management', label: 'AWS Cost Management', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-customer-engagement', label: 'AWS Customer Engagement', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-database', label: 'AWS Database', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-general', label: 'AWS General', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-governance', label: 'AWS Governance', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-group-icons', label: 'AWS Group Icons', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-iot', label: 'AWS IOT', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-media', label: 'AWS Media', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-ml', label: 'AWS ML', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-mobile', label: 'AWS Mobile', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-other', label: 'AWS Other', groups: [ 'Amazon Web Services (AWS)' ],
        libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-security', label: 'AWS Security', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'aws-storage', label: 'AWS Storage', groups: [ 'Amazon Web Services (AWS)' ],
            libGroup: 'AWS', category: 'Technical' },
        { id: 'block', label: 'Block Shapes', groups: [], libGroup: 'Shapes', category: 'Basics' },
        { id: 'bpmn', label: 'BPMN Shapes', groups: [ 'Process' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'chemistry-lab-equipment', label: 'Chemistry Lab', groups: [],
            libGroup: 'Shapes', category: 'Others' },
        { id: 'concept-map', label: 'Concept Maps', groups: [ 'General' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'dfd-gs', label: 'Data Flow Diagrams - GS', groups: [ 'Database, ER & DFD' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'dfd-yc', label: 'Data Flow Diagrams - YC', groups: [ 'Database, ER & DFD' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'entity-relationship-cf', label: 'Entity Relationship - Crows Foot', groups: [ 'Database, ER & DFD' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'entity-relationship-chens', label: 'Entity Relationship - Chens', groups: [ 'Database, ER & DFD' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'flowchart', label: 'Flowchart', groups: [ 'Process', 'General' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'gantt', label: 'Gantt Chart', groups: [ 'Projects ' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'general', label: 'Basic Shapes', groups: [], libGroup: 'Shapes', category: 'Basics' },
        { id: 'genogram', label: 'Basic Genogram Shapes', groups: [ 'Genogram ' ],
            libGroup: 'Shapes', category: 'Others' },
        { id: 'genogram-medical', label: 'Genogram Medical Shapes', groups: [ 'Genogram ' ],
            libGroup: 'Shapes', category: 'Others' },
        { id: 'genogram-colored', label: 'Genogram Colored Shapes', groups: [ 'Genogram ' ],
            libGroup: 'Shapes', category: 'Others' },
        { id: 'genogram-gender-based', label: 'Genogram - Gender Based Shapes', groups: [ 'Genogram ' ],
            libGroup: 'Shapes', category: 'Others' },
        { id: 'venn', label: 'Venn Diagram', groups: [ 'General' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'popular-diagrams', label: 'Popular Diagrams', groups: [ 'General' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'sticky', label: 'Sticky Packs', groups: [ 'General' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'timeline', label: 'Timeline Diagram', groups: [ 'Projects ' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'infographics', label: 'Infographics', groups: [ 'Communications' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'vsm', label: 'Value Stream Map', groups: [ 'Process' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'mindmap', label: 'Mindmaps', groups: [ 'Communications', 'Projects ' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'network-rack', label: 'Network Rack Shapes', groups: [ 'Network Diagrams' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'org-chart', label: 'Organizational Charts', groups: [ 'Human Resources' ],
            libGroup: 'Shapes', category: 'Business' },
        { id: 'simple-network', label: 'Network Shapes', groups: [ 'Network Diagrams' ], libGroup: 'Shapes', category: 'Technical' },
        { id: 'site-map', label: 'Site Maps and UX Flows', groups: [ 'Product' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'stars-and-balloons', label: 'Stars and Balloons', groups: [  ], libGroup: 'Shapes', category: 'Basics' },
        { id: 'swot', label: 'SWOT Diagrams', groups: [ 'Projects ' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'tqm', label: 'Total Quality Management (TQM)', groups: [ 'Process' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'ui-mockup', label: 'UI Mockup', groups: [ 'Product' ], libGroup: 'Shapes', category: 'Business' },
        { id: 'uml-activity', label: 'UML Activity', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'uml-class', label: 'UML Class', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'uml-communication', label: 'UML Collaboration', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'uml-sequence', label: 'UML Sequence', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'uml-database', label: 'Database Diagram', groups: [ 'UML Modeling' ], libGroup: 'Shapes', category: 'Technical' },
        { id: 'database', label: 'Database Diagram', groups: [ 'Database, ER & DFD' ], libGroup: 'Shapes', category: 'Technical' },
        { id: 'uml-state-machine', label: 'UML State Machine', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        // { id: 'uml-deployment', label: 'UML Deployment', groups: [ 'Software' ], libGroup: 'Shapes' },
        // { id: 'uml-component', label: 'UML Component', groups: [ 'Software' ], libGroup: 'Shapes' },
        // { id: 'uml-domain-model', label: 'UML Domain Model', groups: [ 'Software' ], libGroup: 'Shapes' },
        { id: 'use-case', label: 'UML Use Case', groups: [ 'UML Modelling' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'cisco-products', label: 'Cisco Products', groups: [ 'Network Diagrams' ], libGroup: 'Shapes', category: 'Technical' },
        { id: 'cisco-miscellaneous', label: 'Cisco Miscellaneous', groups: [ 'Network Diagrams' ],
            libGroup: 'Shapes', category: 'Technical' },
        { id: 'gcp-ai-and-machine-learning', label: 'Google Cloud AI & Machine Learning', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-api-management', label: 'Google Cloud API Management', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-compute', label: 'Google Cloud Compute', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-data-analytics', label: 'Google Cloud Data Analytics', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-databases', label: 'Google Cloud Databases', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-developer-tools', label: 'Google Cloud Developer Tools', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-hybrid-and-multi-cloud', label: 'Google Cloud Hybrid & Multi Cloud', groups: [ 'Google Cloud Platform (GCP)' ], libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-internet-of-things', label: 'Google Cloud IoT', groups: [ 'Google Cloud Platform (GCP)' ], libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-management-tools', label: 'Google Cloud Management Tools', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-migration', label: 'Google Cloud Migration', groups: [ 'Google Cloud Platform (GCP)' ], libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-networking', label: 'Google Cloud Networking', groups: [ 'Google Cloud Platform (GCP)' ], libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-security', label: 'Google Cloud Security', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'gcp-storage', label: 'Google Cloud Storage', groups: [ 'Google Cloud Platform (GCP)' ],
            libGroup: 'GCP', category: 'Technical' },
        { id: 'azure-ai-and-ml', label: 'Azure AI and ML', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-deprecated', label: 'Azure Deprecated', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-iot', label: 'Azure IoT', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-other', label: 'Azure Other', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-analytics', label: 'Azure Analytics', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-dev-ops', label: 'Azure DevOps', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-management-governance', label: 'Azure Management and Governance', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-security', label: 'Azure Security', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-companies', label: 'Azure Companies', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-general', label: 'Azure General', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-migrate', label: 'Azure Migrate', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-storage', label: 'Azure Storage', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-compute', label: 'Azure Compute', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-identity', label: 'Azure Identity', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-mixed-reality', label: 'Azure Mixed Reality', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-web', label: 'Azure Web', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-container', label: 'Azure Container', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-integration', label: 'Azure Integration', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-mobile', label: 'Azure Mobile', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-databases', label: 'Azure Databases', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-intune', label: 'Azure InTune', groups: [ 'Microsoft Azure' ], libGroup: 'Azure', category: 'Technical' },
        { id: 'azure-networking', label: 'Azure Networking', groups: [ 'Microsoft Azure' ],
            libGroup: 'Azure', category: 'Technical' },
        { id: 'kubernetes-cluster-config', label: 'Kubernetes Cluster Config', groups: [ 'Kubernetes (K8s)' ], libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-compute', label: 'Kubernetes Compute', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-control-plane-comp', label: 'Kubernetes Control Plane Components', groups: [ 'Kubernetes (K8s)' ], libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-group-link', label: 'Kubernetes Groups and Links', groups: [ 'Kubernetes (K8s)' ], libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-infrastructure', label: 'Kubernetes Infrastructure', groups: [ 'Kubernetes (K8s)' ], libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-network', label: 'Kubernetes Network', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-others', label: 'Kubernetes Others', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-pods-config', label: 'Kubernetes Pods Configuration', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-rbac-model', label: 'Kubernetes RBAC Model', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        { id: 'kubernetes-storage', label: 'Kubernetes Storage', groups: [ 'Kubernetes (K8s)' ],
            libGroup: 'K8s', category: 'Technical' },
        ...(
            ( environment.NAME !== 'stage' && environment.NAME !== 'dev' ) ? /* istanbul ignore next */[] : [
                {
                    id: 'experiments',
                    label: 'Experiments',
                    groups: [ 'Experiments' ],
                    libGroup: 'Shapes',
                    category: 'Others',
                },
            ]
        ),
    ];

    protected allLibraryCategories: Array<ILibraryCategoryDefinition> = [
        {
            id: 'Basics',
            label: 'Basics',
            description: 'LABELS.LIBRARY_PANEL.BASICS_DESCRIPTION',
        },
        {
            id: 'Business',
            label: 'Business',
            description: 'LABELS.LIBRARY_PANEL.BUSINESS_DESCRIPTION',
            groups: {
                'General': { groupId: 'General', description: 'LABELS.LIBRARY_PANEL.GENERAL_DESCRIPTION' },
                'Projects ': { groupId: 'Projects', description: 'LABELS.LIBRARY_PANEL.PROJECTS_DESCRIPTION' },
                'Human Resources': { groupId: 'Human Resources', description: 'LABELS.LIBRARY_PANEL.HUMAN_RESOURCES_DESCRIPTION' },
                'Communications': { groupId: 'Communications', description: 'LABELS.LIBRARY_PANEL.COMMUNICATION_DESCRIPTION' },
                'Process': { groupId: 'Process', description: 'LABELS.LIBRARY_PANEL.PROCESS_DESCRIPTION' },
                'Product': { groupId: 'Product', description: 'LABELS.LIBRARY_PANEL.PRODUCT_DESCRIPTION' },
            },
        },
        {
            id: 'Technical',
            label: 'Technical',
            description: 'LABELS.LIBRARY_PANEL.TECHNICAL_DESCRIPTION',
            groups: {
                'Archimate': { groupId: 'Archimate', description: 'LABELS.LIBRARY_PANEL.ARCHIMATE_DESCRIPTION' },
                'Amazon Web Services (AWS)': { groupId: 'Amazon Web Services (AWS)', description: 'LABELS.LIBRARY_PANEL.AWS_DESCRIPTION', link: 'https://creately.com/guides/aws-architecture-diagrams-and-use-cases/' },
                'Database, ER & DFD': { groupId: 'Database, ER & DFD', description: 'LABELS.LIBRARY_PANEL.DATA_DESCRIPTION' },
                'UML Modelling': { groupId: 'UML Modelling', description: 'LABELS.LIBRARY_PANEL.UML_DESCRIPTION', link: 'https://creately.com/blog/diagrams/uml-diagram-objects/' },
                'Network Diagrams': { groupId: 'Network Diagrams', description: 'LABELS.LIBRARY_PANEL.NETWORKS_DESCRIPTION', link: 'https://creately.com/guides/network-diagram-guide-tutorial/' },
                'Microsoft Azure': { groupId: 'Microsoft Azure', description: 'LABELS.LIBRARY_PANEL.MICROSOFT_AZURE_DESCRIPTION' },
                'Kubernetes (K8s)': { groupId: 'Kubernetes (K8s)', description: 'LABELS.LIBRARY_PANEL.KUBERNETES_DESCRIPTION' },
            },
        },
        {
            id: 'Others',
            label: 'Others',
            description: 'LABELS.LIBRARY_PANEL.OTHERS_DESCRIPTION',
            groups: {
                'Genogram ': { groupId: 'Genogram', description: 'LABELS.LIBRARY_PANEL.GENOGRAMS_DESCRIPTION', link: 'https://creately.com/guides/how-to-make-a-genogram/' },
            },
        },
        {
            id: 'Icons',
            label: 'Icons',
            description: '',
        },
        {
            id: 'Custom Databases',
            label: 'Custom Databases',
            description: '',
        },
    ];

    protected allIconsLibraries: Array<ILibraryListItem> = [
        { id: 'icons-education', label: 'Icons - Education', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'icons-people-outlines', label: 'Icons - People Outlines', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'icons-people', label: 'Icons - People', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-image-icons', label: 'Icons - Material Design - Image', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-file-icons', label: 'Icons - Material Design - File', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-alert-icons', label: 'Icons - Material Design - Alert', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-action-icons', label: 'Icons - Material Design - Action', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-notification-icons', label: 'Icons - Material Design - Notification',
            groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-content-icons', label: 'Icons - Material Design - Content', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-maps-icons', label: 'Icons - Material Design - Maps', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-social-icons', label: 'Icons - Material Design - Social', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-navigation-icons', label: 'Icons - Material Design - Navigation', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-hardware-icons', label: 'Icons - Material Design - Hardware', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-places-icons', label: 'Icons - Material Design - Places', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-toggle-icons', label: 'Icons - Material Design - Toggle', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-communication-icons', label: 'Icons - Material Design - Communication',
            groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-av-icons', label: 'Icons - Material Design - AV', groups: [ 'Icons' ],
            libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-editor-icons', label: 'Icons - Material Design - Editor', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
        { id: 'material-design-device-icons', label: 'Icons - Material Design - Device', groups: [ 'Icons' ], libGroup: 'Icons', category: 'Icons' },
    ];

    protected quickToolLibraries: Array<ILibraryListItem> = [
        { id: 'quick-tool-stickers', label: 'Stickers', groups: [ 'Quick Tools' ], libGroup: 'Quick Tools', category: 'Quick Tools' },
        { id: 'quick-tool-database', label: 'Recent Database', groups: [ 'Quick Tools' ],
            libGroup: 'Quick Tools', category: 'Quick Tools' },
        { id: 'quick-tool-recent', label: 'Recent Shapes', groups: [ 'Quick Tools' ], libGroup: 'Quick Tools', category: 'Quick Tools' },
    ];

    protected framesLibraries: Array<ILibraryListItem> = [
        { id: 'grids-boards', label: 'Grids and Boards', groups: [ 'Frames' ], libGroup: 'Frames', category: 'Frames' },
        { id: 'grids-canvases', label: 'Grids and Canvases', groups: [ 'Frames' ],
            libGroup: 'Frames', category: 'Frames' },
        { id: 'frames', label: 'Papre & Device Frames', groups: [ 'Frames' ], libGroup: 'Frames', category: 'Frames' },
        { id: 'swimlanes', label: 'Swimlanes', groups: [ 'Frames' ], libGroup: 'Frames', category: 'Frames' },
    ];

    protected embedsLibraries: Array<ILibraryListItem> = [
        { id: 'video', label: 'Video', groups: [ 'Embeds' ], libGroup: 'Embeds', category: 'Embeds' },
        { id: 'productivity', label: 'Productivity', groups: [ 'Embeds' ], libGroup: 'Embeds', category: 'Embeds' },
    ];

    /**
     * Map of mismatching libIds in 'allShapeLibraries.id' fields
     * Add all mismatching libIds here to match with the required
     * corresponding id that is extracted from defId
     * Used to find whether id belongs to a premium group
     */
    protected mismatchingShapeLibraryIdsMap = [
        { libId: 'creately-aws-a', defId: 'aws-analytics' },
        { libId: 'aws-business-applications', defId: 'aws-business-apps' },
    ];

    /**
     * Premium library groups.
     */
    protected premiumGroups: string[] = [
        'Amazon Web Services (AWS)', 'Software', 'Google Cloud Platform (GCP)', 'Microsoft Azure', 'Kubernetes (K8s)', 'Network Diagrams',
    ];

    /**
     * Returns an array of premium library groups.
     */
    public getPremiumGroups(): string[] {
        return this.premiumGroups;
    }

    /**
     * Checks if a given library belongs to a premium group.
     * @param id the id for the library
     */
    public belongsToPremiumGroup( id: string ): boolean {
        let item = this.allShapeLibraries.find( libItem => libItem.id === id );

        /**
         * FIXME:
         * When extract libId from defId, Some library ids won't match with allShapeLibraries.id.
         * Instead, It only contains the prefix.
         *
         * HACK:
         * If couldn't find libraryId by id, Trying to find by prefix to solve above issue.
         */
        if ( !item ) {
            item = this.allShapeLibraries.find( libItem => libItem.id.startsWith( id ));
        }

        // If libraryId still cannot be found, check in 'mismatchingShapeLibraryIdsMap'
        if ( !item ) {
            const foundItem = Object.entries( this.mismatchingShapeLibraryIdsMap )
                .find(([ _, value ]) => value.defId === id );
            if ( foundItem ) {
                const originalItem = this.allShapeLibraries.find( libItem => libItem.id === foundItem[1].libId );
                if ( originalItem ) {
                    item = { ...originalItem, id: foundItem[1].defId };
                }
            }
        }

        return item ? item.groups.filter( group => this.premiumGroups.includes( group )).length !== 0 : false;
    }

    /**
     * Check whether the group is premium or not
     * @param group
     * @returns
     */
    public isPremiumGroup( group: string ): boolean {
        return this.premiumGroups.includes( group );
    }

    /**
     * Returns all the groups as an array.
     */
    public getAllGroups(): string[] {
        let groupArr = this.getAllShapeLibraries();
        groupArr = groupArr.concat( this.getAllIconsGroups());
        return uniq( groupArr );
    }

    /**
     * Returns all shape library IDs.
     */
    public getAllShapeLibraries(): string[] {
        const groupArr = [];
        this.allShapeLibraries.map( libItem => {
            libItem.groups.map( lib => {
                groupArr.push( lib );
            });
        });
        return uniq( groupArr );
    }

    /**
     * Returns all the various libraries
     */
    public getAllLibrarires(): ILibraryListItem[] {
        const libs = this.allShapeLibraries.concat(
            this.allIconsLibraries, this.framesLibraries, this.embedsLibraries,
        );
        return uniqBy( libs, 'id' );
    }

    /**
     * Returns all the library category
     */
    public getAllLibraryCategories(): string[] {
        const categories  = [];
        this.allShapeLibraries.map( lib => categories.push( lib.category ));
        return uniq( categories );
    }

    /**
     * Returns all icon group IDs.
     */
    public getAllIconsGroups(): string[] {
        const groupArr = [];
        this.allIconsLibraries.map( libItem => {
            libItem.groups.map( lib => {
                groupArr.push( lib );
            });
        });
        return uniq( groupArr );
    }

    /**
     * Returns all icon group IDs.
     */
    public getAllFramesGroups(): string[] {
        const groupArr = [];
        this.framesLibraries.map( libItem => {
            libItem.groups.map( lib => {
                groupArr.push( lib );
            });
        });
        return uniq( groupArr );
    }

    /**
     * Returns all icon group IDs.
     */
    public getAllEmbedsGroups(): string[] {
        const groupArr = [];
        this.embedsLibraries.map( libItem => {
            libItem.groups.map( lib => {
                groupArr.push( lib );
            });
        });
        return uniq( groupArr );
    }

    /**
     * Returns all icon libraries.
     */
    public getAllIconLibraries(): Array<ILibraryListItem> {
        return this.allIconsLibraries;
    }

    /**
     * Returns all frames libraries.
     */
    public getAllFramesLibraries(): Array<ILibraryListItem> {
        return this.framesLibraries;
    }

    /**
     * Returns all embeds libraries.
     */
    public getAllEmbedsLibraries(): Array<ILibraryListItem> {
        return this.embedsLibraries;
    }

    /**
     * Returns all icon libraries.
     */
    public getQuickToolLibraries(): Array<ILibraryListItem> {
        return this.quickToolLibraries;
    }

    /**
     * Returns group IDs for the given library ID.
     */
    public getGroupsForLibrary( libraryId: string ): string[] {
        if ( libraryId.includes( CUSTOM_SHAPE_LIB_ID_PREFIX )) {
            return [ 'Shapes' ];
        }
        const lib = this.allShapeLibraries.concat(
            this.allIconsLibraries, this.framesLibraries, this.embedsLibraries,
        ).find( library => library.id === libraryId );
        return lib?.groups;
    }

    /**
     * Returns group IDs for the given library ID.
     */
    public getMainGroupForLibrary( libraryId: string ): string {
        if ( libraryId.includes( CUSTOM_SHAPE_LIB_ID_PREFIX )) {
            return 'Shapes';
        }
        const lib = this.allShapeLibraries.find( library => library.id === libraryId );
        return lib?.libGroup || 'Shapes';
    }

    /**
     * Get library by id
     * @param libraryId
     */
    public getLibrary( libraryId: string ): ILibraryListItem {
        return this.allShapeLibraries.find( lib => lib.id === libraryId );
    }

    /**
     * Returns the category for the library
     */
    public getCategoryForLibrary( libraryId: string ): string {
        if ( libraryId.includes( CUSTOM_SHAPE_LIB_ID_PREFIX )) {
            return 'Shapes';
        }
        const lib = this.allShapeLibraries.find( library => library.id === libraryId );
        return lib?.category;
    }

    /**
     * Returns all the libraries linked to a group.
     */
    public getLibrariesInGroup( groupName: string ): ILibraryListItem[] {
        return this.allShapeLibraries.concat(
            this.allIconsLibraries, this.framesLibraries, this.embedsLibraries,
        ).filter( libItem => libItem.groups.includes( groupName ));
    }

    /**
     *  Takes in main category and return children to build treeview
     * @param category
     */
    public getCategoryChildren( category: string ): { libraries: ILibraryListItem[], groups: string[] } {
        const libraries = [];
        let groups = [];
        this.allShapeLibraries.map( libItem => {
            if ( libItem.category === category ) {
                if ( libItem.groups.length < 1 ) {
                    libraries.push( libItem );
                }
            }
        });
        groups = [ ...Object.keys( this.allLibraryCategories.find( cat => cat.id === category ).groups || {}) ];
        return { libraries, groups: uniq( groups ) };
    }

    /**
     * Returns the category definition
     * @param category
     */
    public getCategoryDefinition( category ): ILibraryCategoryDefinition {
        return this.allLibraryCategories.find( i => i.id === category );
    }

    /**
     * Takes in a group and returns the category the group falls in
     * @param group
     */
    public getCategoryDefinitionFromGroup( group ): ILibraryCategoryDefinition {
        const category = this.getLibrariesInGroup( group )[ 0 ].category;
        return this.allLibraryCategories.filter( i => i.id === category )[ 0 ];
    }

    /**
     * takes in the libraryId and returns the group and category definition
     * @param libId
     */
    public getLibraryDeepView( libId: string ): { definition: ILibraryCategoryDefinition, group: string } {
        let definition = null;
        let group = '';
        const lib = this.allShapeLibraries.find( library => library.id === libId );

        if ( lib ) {
            group = lib.groups[0];
            definition = this.allLibraryCategories.find( i => i.id === lib.category );
        } else if ( libId.includes( CUSTOM_SHAPE_LIB_ID_PREFIX )) {
            definition = this.allLibraryCategories.find( i => i.id === 'Custom Databases' );
        }

        return { definition, group };
    }

    /**
     * Takes a library and returns other libraries in the same group, or same category if library not in group
     * @param libId
     */
    public getLibrabriesInGroupCategory( libId ): Array<ILibraryListItem> {
        const lib = this.allShapeLibraries.find( library => library.id === libId );
        if ( lib.groups.length ) {
            return this.getLibrariesInGroup( lib.groups[0]);
        } else {
            return this.allShapeLibraries.filter(
                library => library.category === lib.category && library.groups.length === 0 );
        }
    }
}
