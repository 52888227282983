import { Injectable } from '@angular/core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import * as Carota from '@creately/carota';
import { Rectangle } from '@creately/createjs-module';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { TextFormatter } from 'flux-diagram-composer';
import { DEFUALT_TEXT_STYLES, IDataItem, ITextContent, TEXT_PADDING_HORIZONTAL, IShapeDefinition, DataType } from 'flux-definition/src';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Command, StateService } from 'flux-core';
import { tap, take, switchMap } from 'rxjs/operators';
import { CsvUtil } from './../../../base/diagram/export/csv-util';
import { of } from 'rxjs';

/**
 * Array of data Item IDs to be restricted used for sytem purposes, like indicator and card shapes
 */
const SYSTEM = [ 'description', 'tags', 'editors', 'reactions', 'dueDate', 'link', 'estimate' ];

/**
 * Id for dataitems labels text model.
 */
const DATA_ITEMS_LABELS_TEXT_ID = 'data_items_labels';

/**
 * Id for dataitems values text model
 */
const DATA_ITEMS_VALUES_TEXT_ID = 'data_items_values';


/**
 * Command to update the data items rendered within the shape view.
 */
@Injectable()
@Command()
export class ToggleDataFields extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        id: string,
        label: string,
        value: string,
        checkBox: {
            checked: boolean,
        },
        shapeId?: string,
        showAll?: boolean,
    };

    protected formatter: TextFormatter;

    constructor(
        protected ds: DiagramChangeService,
        protected state: StateService<any, any>,
        protected defLocator: DefinitionLocator ) {
        super( ds );
        this.formatter = new TextFormatter();
    }

    public prepareData() {
        if ( this.data.shapeId && this.data.showAll ) {
            const _shape = this.changeModel.shapes[ this.state.get( 'Selected' )[0]] as ShapeModel;
            const dataItems = _shape.getDataItems( this.changeModel );
            Object.keys( dataItems ).forEach( id  => {
                const item = dataItems[id];
                let dataItem: IDataItem<any> = Object.assign({},
                    this.changeModel.getDataItem( this.data.shapeId, this.data.id ));
                if ( dataItem && dataItem.label ) {
                    dataItem.value = item.value;
                } else {
                    dataItem = item;
                }
                if ( this.shouldToggle( item, _shape )) {
                    if ( _shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]) {
                        this.insertIntoText( _shape, dataItem );
                        this.updateShapeScale( _shape );
                        _shape.renderedDataFields = [ ..._shape.renderedDataFields, dataItem.id ];
                    } else {
                        this.createNewText( _shape, dataItem );
                        this.updateShapeScale( _shape );
                        _shape.renderedDataFields = [ ..._shape.renderedDataFields, dataItem.id ];
                    }
                }
            });
        } else {
            let shapeIds = [];
            const shape = this.changeModel.shapes[ this.state.get( 'Selected' )[0]] as ShapeModel;
            const parent = shape.getParent( this.changeModel ) as ShapeModel;
            if ( parent ) {
                shapeIds = Object.keys( parent.children );
            } else {
                shapeIds = [ shape.id ];
            }

            for ( const shapeId of shapeIds ) {
                const _shape = this.changeModel.shapes[ shapeId ] as ShapeModel;
                let dataItem: IDataItem<any> = Object.assign({}, this.changeModel.getDataItem( shapeId, this.data.id ));
                const dataItems = _shape.getDataItems( this.changeModel );
                Object.keys( dataItems ).forEach( key => {
                    const item = dataItems[key];
                    if ( item.label === this.data.label ) {
                        // Handle system data fields like priority that should be toggled
                        if ( dataItem && dataItem.label ) {
                            dataItem.value = item.value;
                        } else {
                            dataItem = item;
                        }
                    }
                });
                if ( !dataItem ) {
                    continue;
                }
                const isVisible = _shape.renderedDataFields.includes( dataItem.id );
                if (( isVisible !== this.data.checkBox.checked && shapeIds.length > 1 ) ||
                    dataItem.label !== this.data.label ) {
                    continue;
                }
                if ( _shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]) {
                    if ( this.data.checkBox.checked ) {
                        this.removeFromText( _shape, dataItem );
                        const newIds = _shape.renderedDataFields.filter( i => i !== dataItem.id );
                        _shape.renderedDataFields = newIds;
                    } else {
                        this.insertIntoText( _shape, dataItem );
                        _shape.renderedDataFields = [ ..._shape.renderedDataFields, dataItem.id ];
                    }
                } else {
                    this.createNewText( _shape, dataItem );
                    _shape.renderedDataFields = [ ..._shape.renderedDataFields, dataItem.id ];
                }
                this.updateShapeScale( _shape );
            }
        }
    }

    /**
     * Position primary text back to center of shape when all dataItems are removed
     * @param shape
     */
    protected repositionPrimaryText( shape: ShapeModel ) {
        if ( Object.keys( shape.texts ).length === 1 ) {
            const primary: any = shape.primaryTextModel;
            if ( primary.rendering === 'tiptapCanvas' || primary.rendering === 'dom' ) {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).pipe(
                    tap( formatted => {
                        if ( formatted ) {
                            primary.html = formatted;
                        }
                    }),
                ).subscribe();
            } else {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).subscribe();
                const primaryContent = this.formatter.applyRaw(
                    primary.content, { indexStart: 0, indexEnd: undefined, styles: { align: 'center' }}, false );
                primary.content = primaryContent;
                primary.value = Carota.html.html( primaryContent );
            }
            this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
                take( 1 ),
                switchMap(( defn: IShapeDefinition ) => {
                    const defPrimaryText = defn.texts[ primary.id ];
                    primary.xType = 'relative';
                    primary.yType = 'relative';
                    primary.positionString = 'in-center-center';
                    primary.x = 0.5;
                    primary.y = 0.5;
                    primary.alignX = 0;
                    primary.alignY = 0;
                    primary._alignX = 0;

                    if ( defPrimaryText.yType && defPrimaryText.y ) {
                        primary.yType = defPrimaryText.yType;
                        primary.y = defPrimaryText.y;
                    }

                    if ( defn.transformSettings && defn.transformSettings.hasOwnProperty( 'rotate' )) {
                        shape.transformSettings.rotate = defn.transformSettings.rotate;
                    } else {
                        shape.transformSettings.rotate = true;
                    }
                    if ( defn.transformSettings && defn.transformSettings.hasOwnProperty( 'fixAspectRatio' )) {
                        shape.transformSettings.fixAspectRatio = defn.transformSettings.fixAspectRatio;
                    } else {
                        shape.transformSettings.fixAspectRatio = false;
                    }
                    return of( null );
                })).subscribe();
        }
    }

    protected createNewText( shape: ShapeModel, dataItem: IDataItem<any> ) {
        if ( Object.keys( shape.texts ).length === 1 ) {
            const primary: any = shape.primaryTextModel;
            if ( primary.rendering === 'tiptapCanvas' || primary.rendering === 'dom' ) {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'left' }).pipe(
                    tap( formatted => {
                        if ( formatted ) {
                            primary.html = formatted;
                        }
                    }),
                ).subscribe();
            } else {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'left' }).subscribe();
                const primaryContent = this.formatter.applyRaw(
                    primary.content, { indexStart: 0, indexEnd: undefined, styles: { align: 'left' }}, false );
                primary.content = primaryContent;
                primary.value = Carota.html.html( primaryContent );
            }
            primary.xType = 'fixed-start';
            primary.yType = 'fixed-start';
            primary.positionString = 'in-top-left';
            primary.x = 10;
            primary.y = 10;
            primary.alignX = -1;
            primary.alignY = -1;
            primary._alignX = -1;
        }

        const color = shape.style.textColor || '#687376';
        const label: any = [
            {
                text: dataItem.label,
                color: color,
                size: 10,
                font: 'noto_regular',
                align: 'left',
                bold: false,
                italic: false,
                underline: false,
                strikeout: false,
                script: 'normal',
                data_item: dataItem.id,
            },
        ];
        const value: any = [
            {
                text: this.parseDataItem( dataItem ),
                color: color,
                size: 10,
                font: 'noto_regular',
                align: 'right',
                bold: false,
                italic: false,
                underline: false,
                strikeout: false,
                script: 'normal',
                data_item: dataItem.id,
            },
        ];
        const handlebars = {};
        handlebars[ dataItem.label ] = dataItem.value;
        const labelText: any = {
            primary: false,
            id: DATA_ITEMS_LABELS_TEXT_ID,
            preferredColor: 'custom',
            x: 10,
            y: 10,
            xType: 'fixed-start',
            yType: 'fixed-end',
            alignX: -1,
            alignY: 1,
            positionString: 'in-bottom-left',
            value: `<span style="color:#1a1a1a; text-align: left;"></span>`,
            wordWrap: false,
            content: label,
            editable: false,
        };
        const valueText: any = {
            primary: false,
            id: DATA_ITEMS_VALUES_TEXT_ID,
            preferredColor: 'custom',
            x: 10,
            y: 10,
            xType: 'fixed-end',
            yType: 'fixed-end',
            alignX: 1,
            alignY: 1,
            positionString: 'in-bottom-right',
            value: `<span style="color:#1a1a1a; text-align: right;"></span>`,
            wordWrap: false,
            content: value,
            editable: false,
        };

        if ( label.length > 0 ) {
            const bounds = this.calculateBounds( label );
            labelText.width = bounds.width;
            labelText.height = bounds.height;
            labelText.handlebars = JSON.stringify( handlebars );
            shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ] = labelText;
        }
        if ( value.length > 0 ) {
            const bounds = this.calculateBounds( value );
            valueText.width = bounds.width;
            valueText.height = bounds.height;
            valueText.handlebars = JSON.stringify( handlebars );
            shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ] = valueText;
        }
    }

    protected removeFromText ( shape: any, dataItem: IDataItem<any> ) {
        const labelText = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ];
        const valueText = shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ];
        const lcontent = labelText.content.filter(( text: any ) => text.data_item !== dataItem.id );
        const vcontent = valueText.content.filter(( text: any ) => text.data_item !== dataItem.id );
        const handlebars = JSON.parse( labelText.handlebars );
        delete handlebars[dataItem.label];
        const lbounds = this.calculateBounds( lcontent );
        const vbounds = this.calculateBounds( vcontent );
        if ( lcontent.length > 0 ) {
            labelText.handlebars = JSON.stringify( handlebars );
            labelText.content = lcontent;
            labelText.width = lbounds.width;
            labelText.height = lbounds.height;
        } else {
            delete shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ];
        }
        if ( vcontent.length > 0 ) {
            valueText.handlebars = JSON.stringify( handlebars );
            valueText.content = vcontent;
            valueText.width = vbounds.width;
            valueText.height = vbounds.height;
        } else {
            delete shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ];
            this.repositionPrimaryText( shape );
        }
    }

    protected insertIntoText ( shape: ShapeModel, dataItem: IDataItem<any> ) {
        const labelText: any = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ];
        const valueText: any = shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ];
        const color = shape.style.textColor || '#687376';
        const label: any = {
            text: `\n${dataItem.label}`,
            color: color,
            size: 10,
            font: 'noto_regular',
            align: 'left',
            bold: false,
            italic: false,
            underline: false,
            strikeout: false,
            script: 'normal',
            data_item: dataItem.id,
        };
        const value: any = {
            text: `\n${this.parseDataItem( dataItem )}`,
            color: color,
            size: 10,
            font: 'noto_regular',
            align: 'right',
            bold: false,
            italic: false,
            underline: false,
            strikeout: false,
            script: 'normal',
            data_item: dataItem.id,
        };
        const lContent = [ ...labelText.content, label ];
        const vContent = [ ...valueText.content, value ];
        const lBounds = this.calculateBounds( lContent );
        const vBounds = this.calculateBounds( vContent );
        labelText.width = lBounds.width;
        labelText.height = lBounds.height;
        labelText.content = lContent;
        valueText.width = vBounds.width;
        valueText.height = vBounds.height;
        valueText.content = vContent;
        // const handlebars = JSON.parse( labelText.handlebars );
        // handlebars[dataItem.label] = dataItem.value;
        // labelText.handlebars = JSON.stringify( handlebars );
    }

    protected calculateBounds( content: ITextContent[]): Rectangle {
        return Carota.bounds( content, 999999, DEFUALT_TEXT_STYLES );
    }

    protected updateShapeScale( shape: ShapeModel ) {
        const primary = shape.primaryTextModel;
        const dataTextHeight = Math.max( shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]?.height,
            shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ]?.height );
        const dataWidth = shape.texts[ DATA_ITEMS_LABELS_TEXT_ID ]?.width +
            shape.texts[ DATA_ITEMS_VALUES_TEXT_ID ]?.width + 4 * TEXT_PADDING_HORIZONTAL;
        if ( dataWidth ) {
            shape.scaleX = Math.max( shape.scaleX,
                Math.max( dataWidth, primary.width + 2 * TEXT_PADDING_HORIZONTAL ) / shape.defaultBounds.width );
            shape.minBounds = { ...shape.minBounds, width: dataWidth };
        }
        if ( dataTextHeight ) {
            shape.scaleY = ( dataTextHeight + primary.height + 25 ) / shape.defaultBounds.height;
            const transformSettings = { ...shape.transformSettings, fixAspectRatio: false, rotate: false };
            shape.transformSettings = transformSettings;
        } else {
            if ( Object.keys( shape.texts ).length === 1 ) {
                shape.scaleY = Math.max((( primary.height + 10 ) / shape.defaultBounds.height ), 1 );
            }
            delete shape.minBounds;
        }
    }

    protected parseDataItem( dataItem ): string {
        if ( dataItem.type === DataType.DATE && dataItem.value ) {
            const options: Intl.DateTimeFormatOptions = {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            };

            const date = new Date( dataItem.value );
            return date.toLocaleDateString( 'en-US', options );
        }
        const formatter = CsvUtil.getFormatter( dataItem.type );
        return formatter( dataItem );
    }

    /**
     * Filters out data items which should not be shown in shapes
     * @param dataItem
     */
    protected shouldToggle ( dataItem, shape: ShapeModel ): any {
        const libId = shape.defId.split( '.' ) [ 1 ];
        if ( shape.isDataFieldsDisabledInView || SYSTEM.includes( dataItem.id ) || String( dataItem.id )[0] === '_' ||
            !dataItem.label || dataItem.hasOwnProperty( 'views' ) || dataItem.type === DataType.PEOPLE ||
            dataItem.type === DataType.USERS || dataItem.type === DataType.LOOKUP || libId.includes( 'icons' ) ||
            !shape.data[ dataItem.id ] || shape.isImage() || shape.renderedDataFields.includes( dataItem.id ) ||
            dataItem?.visibility?.length === 0 || !this.parseDataItem( dataItem )) {
            return null;
        }
        const checked = shape.renderedDataFields?.includes( dataItem.id );
        return { ...dataItem, checkBox: { checked: checked }};
    }
}


Object.defineProperty( ToggleDataFields, 'name', {
    value: 'ToggleDataFields',
});
