import { pick } from 'lodash';
import { Injectable } from '@angular/core';
import { Random, Command, StateService } from 'flux-core';
import { IConnectorPoint, IConnectorEndPoint, DEFAULT_CONNECTOR } from 'flux-diagram-composer';
import { INewConnectorConnection } from '../../../base/shape/connection/connection.i';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { LineStyle } from '../../feature/style/line-style';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

/**
 * AddDiagramConnector
 * Adds a new connector on the diagram with given points. It will also try to select
 * the best type for the connector by the shapes the connector is connected to.
 */
@Injectable()
@Command()
export class AddConnector extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The entryClass is the full class identifier as used in shape definitions.
     */
    public data: {
        id: string,
        points: IConnectorPoint[],
        defId?: any,
        version?: any,
        style?: any,
        texts?: any,
        entryClass?: string,
        connectorToCopyStylesFrom?: ConnectorModel,
    };
    constructor(
        protected ds: DiagramChangeService,
        protected state: StateService<any, any>,
    ) {
        super( ds );
    }
    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        this.resultData = { id: this.data.id };
        const toCopy = this.data.connectorToCopyStylesFrom || {} as ConnectorModel;
        const otherData = pick( this.data, [ 'defId', 'version', 'entryClass', 'style', 'texts' ]);
        const connection = this.prepareConnection( this.data.points );
        const defId = connection?.definitions[0].defId;
        const connector = this.prepareConnector(
            this.data.id,
            this.data.points,
            otherData,
            ( !defId && toCopy.defId === 'creately.connector.default' ) ? toCopy :
                defId && defId === toCopy.defId ? toCopy : {} as ConnectorModel,
        );
        if ( connection ) {
            const definition = connection.definitions[0];
            connector.defId = definition.defId;
            connector.version = definition.version;
            connector.connectionId = Random.connectionId();
            this.changeModel.connections[connector.connectionId] = connection.connection;
        }
        connector.visible = true; // This is because the connector we copy style from might be hidden
        this.changeModel.shapes[connector.id] = connector;
    }

    /**
     * Prepares connector model data
     */
    private prepareConnector( id: string, points: IConnectorPoint[], otherData: object, toCopy: ConnectorModel ): any {
        return {
            ...toCopy,
            id: id,
            texts: {},
            type: 'connector',
            hidden: false,
            defId: DEFAULT_CONNECTOR.defId,
            version: DEFAULT_CONNECTOR.version,
            path: ConnectorModel.createPathFromPoints( points ),
            zIndex: this.changeModel.maxZIndex + 1,
            style: { ...toCopy?.style, lineColor: toCopy?.style?.lineColor ?
                this.rgbaToHex( toCopy.style.lineColor ) : new LineStyle().lineColor },
            ...otherData,
            createdBy: {
                userId: this.state.get( 'CurrentUser' ),
                time: new Date().getTime(),
            },
        };
    }

    /**
     * Returns information to create a new connector connection for a connector.
     */
    private prepareConnection( points: IConnectorPoint[]): INewConnectorConnection {
        const shapeAId = ( points[0] as IConnectorEndPoint ).shapeId;
        const shapeBId = ( points[ points.length - 1 ] as IConnectorEndPoint ).shapeId;
        if ( !shapeAId || !shapeBId || shapeAId === shapeBId ) {
            return null;
        }
        // we only get potential connections if the shapes are flowchart shapes,
        // because only flowchart shapes have ports
        const connections = this.changeModel.getPotentialConnections( shapeAId, shapeBId )
            .filter( conn => conn.connection.type === 'connector' );
        return connections[0] || null;
    }

    /**
     * Converts an rgba color code to corresponding hex code.
     * It ignores the alpha value and uses just the rgb values.
     * Conversion is necessary if connectorToCopyStylesFrom it is hidden.
     * @param color
     */
    private rgbaToHex( color: string ) {
        const rgba = color.replace( /^rgba?\(|\s+|\)$/g, '' ).split( ',' );
        if ( rgba.length === 3 || rgba.length === 4 ) {
            // tslint:disable-next-line:no-bitwise
            const hex = `#${(( 1 << 24 ) + ( parseInt( rgba[ 0 ], 10 ) << 16 ) + ( parseInt( rgba[ 1 ], 10 ) << 8 ) + parseInt( rgba[ 2 ], 10 )).toString( 16 ).slice( 1 )}`;
            return hex;
        } else {
            return color;
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AddConnector, 'name', {
    value: 'AddConnector',
});
