import { IPoint, IPoint2D } from 'flux-definition';
import { AbstractConnector } from './connector-abstract';
import { IConnectorPoint } from '../../../../shape/model/connector-data-mdl';

/**
 * This class contains the capability to draw a smooth angled connector
 * with divider in the middle using the coordinate data stored into the model.
 *
 * Smooth Angled connectors are drawn similar to angled connectors but
 * the turns are smoothed out. See ConnectorAngled for more info.
 *
 */
export class ConnectorAngledDivided extends AbstractConnector {
    /**
     * Use flag to ensure we only draw one divider even after path changes
     */
    public dividerFlag = false;
    public midPoint: IPoint2D;

    /**
     * Constructor
     */
    public constructor() {
        super()/* istanbul ignore next */;
    }

    /**
     * Draws the smooth angled connector.
     */
    public draw() {
        const points = this.points || this.model.getPoints();
        this.midPoint = this.model.splitByLength( this.model.length / 2 );
        if ( !points.length ) {
            return;
        }
        // start from the head point
        this.moveTo( points[0]);
        // connect all other points
        for ( let i = 1; i < points.length; ++i ) {
            const point = points[i];
            const head = this.geometry.createPoint( point.x, point.y );
            const next = this.nextBumpStart( head, points[ i + 1 ]) || point;
            if ( !point.c1 ) {
                this.drawLine( next, point.bumps && point.bumps[0]);
            } else {
                this.drawLine( point.c1, point.bumps && point.bumps[0]);
                this.drawLine( next, point.bumps && point.bumps[1]);
            }
        }
        this.drawDivider(
            this.model.name,
            { x: this.midPoint.x - 10, y: this.midPoint.y },
            { x: this.midPoint.x + 10, y: this.midPoint.y },
        );
        this.dividerFlag = false;
    }

    public drawLine( point, bump? ) {
        if ( this.model.getNearestPoint( this.midPoint ) && !this.dividerFlag ) {
            this.dividerFlag = true;
            return this.lineTo( point, bump && bump );
        } else {
            return this.lineTo( point, bump && bump );
        }
    }

    public drawDivider( name: string, start: IPoint2D, end: IPoint2D ) {
        this.graphics.setStrokeDash([]);
        this.graphics.beginFill( '#ffffff' );
        switch ( name ) {
            case 'Engagement and Cohabitation':
            case 'Legal Cohabitation':
            case 'Cohabitation':
                this.moveTo({ x: start.x, y: start.y + 10 });
                this.lineTo({ x: start.x, y: start.y  });
                this.lineTo({ x: start.x + 10, y: start.y - 10 });
                this.lineTo({ x: end.x, y: start.y  });
                this.lineTo({ x: end.x, y: start.y + 10 });
                this.lineTo({ x: start.x, y: start.y + 10 });
                this.moveTo( end );
                break;
            case 'Widowed':
                this.moveTo({ x: start.x, y: start.y - 10 });
                this.lineTo({ x: end.x, y: end.y + 10 });
                this.moveTo({ x: start.x, y: start.y + 10 });
                this.lineTo({ x: end.x, y: end.y - 10 });
                break;
            case 'Divorced':
                this.moveTo({ x: start.x - 10, y: start.y + 10 });
                this.lineTo({ x: start.x + 10, y: start.y - 10 });
                this.moveTo({ x: end.x - 10, y: end.y + 10 });
                this.lineTo({ x: end.x + 10, y: end.y - 10 });
                break;
            case 'Legal Separation':
                this.moveTo({ x: start.x, y: start.y + 10 });
                this.lineTo({ x: end.x, y: end.y - 10 });
                break;
            case 'Separation In Fact':
            case 'Engagement And Separation':
                this.moveTo({ x: end.x, y: end.y + 10 });
                this.lineTo({ x: start.x, y: start.y - 10 });
                break;
            default:
                this.moveTo( start );
                this.lineTo( end );
                break;
        }
    }

    /**
     * Returns the point where the next segment first bump starts
     * only when it has to start before the line starting point.
     * This is possible only because angled connector path points are
     * always in the middle of the line part of the path.
     */
    private nextBumpStart( head: IPoint, next: IConnectorPoint ): IPoint {
        if ( !next || !next.bumps || !next.bumps[0] || !next.bumps[0].length ) {
            return null;
        }
        const bump = next.bumps[0][0];
        const radius = bump.radius || AbstractConnector.DEFAULT_BUMP_RADIUS;
        if ( head.distanceTo( bump ) > radius ) {
            return null;
        }
        const line = this.geometry.createLine( head, bump );
        return line.splitByLength( radius, false );
    }

}

Object.defineProperty( ConnectorAngledDivided, 'name', {
    value: 'ConnectorAngledDivided',
});
