import { AbstractArrowHead, IDrawArrowheadOptions } from './arrow-head-abstract';
import { IGraphics } from 'flux-definition';

/**
 * PointerHollow arrow head entry class is one of the default arrow head types.
 */
/* istanbul ignore next */
export class PointerHollow extends AbstractArrowHead {
    /**
     * Draws the arrow head using given graphics with given bounds and transform
     */
    public drawToSize( graphics: IGraphics, options: IDrawArrowheadOptions ): void {
        const bounds = options.bounds;

        const width = bounds.width / 1.15;
        const height = bounds.height;

        graphics.beginFill( '#FFFFFF' );
        this.moveTo( graphics, { x: bounds.x, y: bounds.y }, options.matrix );
        this.lineTo( graphics, { x: bounds.x - width, y: bounds.y - height / 2 }, options.matrix );
        this.lineTo( graphics, { x: bounds.x - width, y: bounds.y + height / 2 }, options.matrix );
        graphics.closePath();
    }
}

Object.defineProperty( PointerHollow, 'name', {
    value: 'PointerHollow',
});
