import { Injectable } from '@angular/core';
import { AbstractAuthentication } from 'flux-connection';
import { AppConfig, AppPlatform, Logger } from 'flux-core';
import { Authentication, AuthenticationStatus } from 'flux-user';
import { Observable } from 'rxjs';
import { PluginAuthentication } from '../plugin/plugin-authentication.svc';

/**
 * Nucleus app can be distributed in multiple ways ( online, plugin, desktop, etc. ).
 * The way the app authenticates may also change.
 */
@Injectable()
export class NucleusAuthentication {
    /**
     * Contains the authentication service which will be used.
     */
    private instance: Authentication | PluginAuthentication;

    /**
     * Initialize the instance property which will be used by all public methods.
     * NOTE: Injecting AbstractAuthentication also resolves to Authentication
     */
    constructor( gAuth: AbstractAuthentication, pAuth: PluginAuthentication ) {
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            Logger.debug( 'NucleusAuthentication: using the PluginAuthentication service' );
            this.instance = pAuth;
        } else {
            Logger.debug( 'NucleusAuthentication: using the default Authentication service' );
            this.instance = gAuth as any;
        }
        // ensures that intialize runs only once for the env.
        this.instance.initialize();
    }

    /**
     * Returns the auth token of the logged in user.
     */
    public get token(): string {
        // NOTE: create a pseudo user auth token.
        return this.instance.token;
    }

    /**
     * Returns the auth token of the logged in user.
     */
    public set token( token: string ) {
        this.instance.token = token;
    }

    /**
     * Returns whether a valid user has logged in.
     */
    public get isAuthenticated(): boolean {
        return this.instance.isAuthenticated;
    }

    /**
     * Returns the logged-in user's id.
     */
    public get currentUserId(): string {
        // TODO: return the user id from app context
        return this.instance.currentUserId;
    }

    /**
     * Returns whether this kind/platform of Creately can
     * be accessed by this token.
     */
    public isAppPlatformAuthorized( app: AppPlatform ) {
        return this.instance.isAppPlatformAuthorized( app );
    }

    /**
     * Logs the user out and clears all locally cached data.
     */
    public logOut(): Observable<any> {
        return this.instance.logOut();
    }

    /**
     * Override method which shows the gravity login widget.
     * @unsupported
     */
    public showLogin(): Observable<AuthenticationStatus> {
        return this.instance.showLogin();
    }

    /**
     * Override method which shows the gravity signup widget.
     * @unsupported
     */
    public showSignUp(): Observable<AuthenticationStatus> {
        return this.instance.showSignUp();
    }
}
