import { IConnectorDefinition } from 'flux-definition';
import { ConnectorCurved } from '../framework/connector/definition/source/connector-curved';
import { ConnectorAngled } from '../framework/connector/definition/source/connector-angled-straight';
import { ConnectorSmoothAngled } from '../framework/connector/definition/source/connector-angled-smooth';
import { ConnectorStraight } from '../framework/connector/definition/source/connector-straight';
import { ConnectorWavy } from '../framework/connector/definition/source/connector-wavy';
import { ConnectorDouble } from '../framework/connector/definition/source/connector-double';
import { ConnectorDivided } from '../framework/connector/definition/source/connector-divided';
import { ConnectorDoubleWavy } from '../framework/connector/definition/source/connector-double-wavy';
import { ConnectorTriple } from '../framework/connector/definition/source/connector-triple';
import { ConnectorAngledDivided } from '../framework/connector/definition/source/connector-angled-divided';
import { ConnectorIndented } from '../framework/connector/definition/source/connector-indented';
import { PointerFilled } from '../framework/connector/definition/source/arrow-head-pointer-filled';
import { PointerDouble } from '../framework/connector/definition/source/arrow-head-pointer-double';
import { PointerLine } from '../framework/connector/definition/source/arrow-head-pointer-line';
import { PointerPointed } from '../framework/connector/definition/source/arrow-head-pointer-pointed';
import { PointerBarbedFilled } from '../framework/connector/definition/source/arrow-head-pointer-wide-barbed-filled';
import { PointerBarbedLine } from '../framework/connector/definition/source/arrow-head-pointer-wide-barbed-line';
import { PointerHollow } from '../framework/connector/definition/source/arrow-head-pointer-hollow';
import { PointerIndented } from '../framework/connector/definition/source/arrow-head-pointer-indented';
import { DEFAULT_CONNECTOR } from './definition/default-connector.def';

export const definitions: IConnectorDefinition[] = [
    DEFAULT_CONNECTOR,
];

export const sourceFiles: Function[] = [
    // draw styles
    ConnectorCurved,
    ConnectorAngled,
    ConnectorSmoothAngled,
    ConnectorStraight,
    ConnectorWavy,
    ConnectorDouble,
    ConnectorDivided,
    ConnectorDoubleWavy,
    ConnectorTriple,
    ConnectorAngledDivided,
    ConnectorIndented,

    // arrow heads
    PointerFilled,
    PointerHollow,
    PointerLine,
    PointerDouble,
    PointerPointed,
    PointerBarbedFilled,
    PointerBarbedLine,
    PointerIndented,
];
