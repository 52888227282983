import { Injectable } from '@angular/core';
import { Command, CommandService, Rectangle, StateService } from 'flux-core';
import { IShapeDefinition, ShapeType } from 'flux-definition';
import { LogicClassFactory } from 'flux-diagram-composer';
import { Observable, empty, from, merge } from 'rxjs';
import { ignoreElements, switchMap, take, tap, map, filter, concatMap } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { EntityLinkService } from '../../../base/edata/entity-link.svc';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { Restriction } from '../restriction/restriction.svc';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';

/**
 * AddDiagramShape
 * Adds a shape on the diagram.
 */
@Injectable()
@Command()
export class AddDiagramShape extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        cloned?: boolean,
        shapes: {
            [ shapeId: string ]: {
                id: string,
                defId: string,
                version: number,
                // NOTE: "type"   required for non-basic shapes.
                //       "path"   required for connectors.
                //       "zIndex" required for templates.
                [ shapeId: string ]: any,
            },
        },
        groups?: {
            [ groupId: string ]: {
                shapes: string[],
                groups: string[],
            },
        },
        dataDefs?: any,
        connections?: {
            [ connectionId: string ]: any,
        },
        entity?: EntityModel, // edata is synced to the shape in the next command in sequence
        templateData?: { templateId: string, context: string },
        frameId?: string, // Adding templates into already existing diagrams should go into a frame
    };

    /**
     * Inject restriction service and the definition locator.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected restriction: Restriction,
        protected defLocator: DefinitionLocator,
        protected state: StateService<any, any>,
        protected commandService: CommandService,
    ) {
        super( ds );
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): Observable<unknown> {
        this.resultData = { shapes: {}};
        return merge(
            this.insertShapes(),
            this.insertGroups(),
            this.insertConnections(),
        );
    }

    /**
     * Part of prepareData hook, adds new shapes.
     */
    private insertShapes(): Observable<unknown> {
        if ( this.data.templateData ) {
            const template = {
                id: this.data.templateData.templateId,
                context: this.data.templateData.context,
            };
            if ( !this.changeModel.templates  ) {
                this.changeModel.templates = [ template ];
            } else {
                this.changeModel.templates = [ ...this.changeModel.templates, template ];
            }
        }
        if ( this.data.cloned ) {
            return this.insertTemplate( this.data.shapes );
        } else {
            const observables = Object.keys( this.data.shapes ).map( shapeId => {
                const shape = this.data.shapes[shapeId];
                return this.insertShape( shape );
            });
            return merge( ...observables );
        }
    }

    /**
     * Part of prepareData hook, adds new groups.
     */
    private insertGroups(): Observable<unknown> {
        for ( const groupId in this.data.groups ) {
            const group = this.data.groups[groupId];
            this.changeModel.groups[groupId] = group;
        }
        return empty();
    }

    /**
     * Part of prepareData hook, adds new connections.
     */
    private insertConnections(): Observable<unknown> {
        for ( const connectionId in this.data.connections ) {
            const connection = this.data.connections[connectionId];
            this.changeModel.connections[connectionId] = connection;
        }
        return empty();
    }

    /**
     * Insert shapes taken from a template.
     */
    private insertTemplate( shapes: any ): Observable<any> {
        const txn = { // txn for eData drag drop
            sessionId: this.state.get( 'SessionId' ),
            uid: this.state.get( 'CurrentUser' ),
        };

        let frame: any = {};

        if ( this.data.frameId ) {
            frame = this.changeModel.shapes[this.data.frameId] as ShapeModel;
        }

        const bounds = frame.bounds || new Rectangle( 0, 0, 0, 0 );
        const children = {};

        const baseIndex = this.changeModel.maxZIndex + 1;
        return from( Object.keys( shapes )).pipe(
            map( shapeId =>  {
                const shape = shapes[shapeId];
                const zIndex = shape.zIndex + baseIndex;

                // if it has eData, prep it for insert
                if ( shape.eData ) {
                    delete shape.eData;
                    shape.txn = txn;
                }

                if ( frame && !shape.containerId ) {
                    shape.containerId = this.data.frameId;
                }
                this.changeModel.shapes[shapeId] = { ...shape, zIndex } as any;
                this.changeModel.dataDefs = { ...this.changeModel.dataDefs, ...this.data.dataDefs };
                return shape as ShapeModel;
            }),
            filter( shape =>  shape.type !== 'connector' ),
            concatMap( shape => this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
                map(( shapeDef: any ) => {
                    if ( shapeDef ) {
                        const shapeBounds: Rectangle = new Rectangle( shape.x, shape.y,
                            shapeDef.defaultBounds.width * shape?.scaleY || 1,
                            shapeDef.defaultBounds.height * shape?.scaleX || 1 );
                        bounds.absorb( shapeBounds );
                    }
                    children[ shape.id ] = { originalZIndex: shape.zIndex };
                    return ({ bounds, children });
                }),
                tap( data => {
                    const b = data.bounds;
                    frame.scaleX = ( b.width + 120 ) / frame?.defaultBounds?.width;
                    frame.scaleY = ( b.height + 60 ) / frame?.defaultBounds?.height;
                    frame.children = data.children;
                }),
            )),
        );
    }

    /**
     * Adds a shape with given data to the diagram.
     */
    private insertShape( shape: any ): Observable<void> {
        if ( shape.type !== ShapeType.Connector ) {
            const position = { x: shape.x || 0, y: shape.y || 0 };
            const restricted = this.restriction.point( position, [ 'GridService' ]);
            shape.x = restricted.x;
            shape.y = restricted.y;
        }

        shape.createdBy = {
            userId: this.state.get( 'CurrentUser' ),
            time: new Date().getTime(),
        };

        return this.defLocator.getDefinition( shape.defId, shape.version ).pipe(
            take( 1 ),
            switchMap(( def: IShapeDefinition ) => {
                // if shape is an edataShape we put the sessionID as a transaction var
                // as this is a 2 part action of adding the shape as well as the entity
                // and it should not be run twice.
                const zIndex = this.changeModel.getIndexToAdd( def );
                if ( def.triggerNewEData || shape.triggerNewEData ) {
                    ( shape as any ).txn = {
                            sessionId: this.state.get( 'SessionId' ),
                            uid: this.state.get( 'CurrentUser' ),
                        };
                    if ( this.data.entity && this.data.entity.style ) {
                        const bounds = this.data.entity.style?.bounds;
                        shape.shapeContext = this.data.entity.defaultShapeContext;
                        if ( this.data.entity.style.shape ) {
                            shape.style = this.data.entity.style.shape;
                        }
                        if ( bounds ) {
                            shape.scaleX = bounds.width / bounds.defaultBounds.width;
                            shape.scaleY = bounds.height / bounds.defaultBounds.height;
                            shape.userSetWidth = bounds.width;
                            shape.userSetHeight = bounds.height;
                            shape.angle = bounds.angle;
                        }
                    }
                    if ( this.data.entity && shape.type === ShapeType.EData ) {
                        /**
                         * If shape is a custom edata shape, we need to add the entity to the shape
                         */
                        if ( EDataRegistry.customEdataDefId ===  this.data.entity.defId ) {
                            Object.assign( shape.data, ( def as any ).dataDef );
                            const entityDefs = this.data.entity.getDef();
                            Object.keys( shape.data ).forEach(( key: string ) => {
                                if ( entityDefs && entityDefs.dataItems[key]) {
                                    shape.data[key].value = this.data.entity.data[key] ||
                                    entityDefs.dataItems[key].default;
                                }
                            });
                        } else {
                            const entityDefs = this.data.entity.getDef();
                            if ( entityDefs.shapeDefs[shape.defId]) {
                                Object.assign( shape.data, ( def as any ).dataDef );
                                const dataMap = entityDefs.shapeDefs[shape.defId].dataMap;
                                dataMap.forEach(( data: any ) => {
                                    shape.data[data.dataItemId].value = this.data.entity.data[data.eDataFieldId] ||
                                         entityDefs.dataItems[data.dataItemId].default;
                                });
                            }
                        }
                    }
                    const createConnectors = true; // this.data.createConnectors;
                    if ( this.data.entity && this.data.entity.links && createConnectors ) {
                        this.createLinkedConnectors( shape, zIndex, def );
                    }
                }
                shape.triggerNewEData = shape.triggerNewEData || def.triggerNewEData;
                shape.eDataCandidates = shape.eDataCandidates || ( def as any ).eDataCandidates;
                const defaultBoundDef = new Rectangle( 0, 0, def.defaultBounds.width, def.defaultBounds.height );
                const { textStyle, ...shapeDef } = shape;
                this.changeModel.shapes[shape.id] = {
                    ...shapeDef,
                    defaultBounds: shape.blank ? shape.defaultBounds : defaultBoundDef,
                    type: shape.type || ShapeType.Basic,
                    zIndex,
                } as any;
                this.resultData.shapes[shape.id] = this.changeModel.shapes[shape.id];
                const applyTextStyles = () => {
                    const texts = Object.assign({}, def.texts, this.changeModel.shapes[shape.id].texts );
                    if ( textStyle && texts ) {
                        if ( !this.changeModel.shapes[shape.id].texts ) {
                            this.changeModel.shapes[shape.id].texts = {};
                        }
                        Object.keys( texts ).forEach( txtId => {
                            const text = texts[txtId];
                            const styles = textStyle[txtId];
                            if ( text && text.content && styles ) {
                                this.changeModel.shapes[shape.id].texts[ txtId ] = {
                                    ...text,
                                    content: text.content.map(( c, i ) => Object.assign( c, styles[i])),
                                };
                                // NOTE: not updating text value. not sure where it's used
                            }
                        });
                    }
                };
                if ( !def.logicClass ) {
                    applyTextStyles();
                    return empty();
                }
                return this.defLocator.getClass( def.logicClass ).pipe(
                    tap(( logicClass: any ) => {
                        const instance = LogicClassFactory.instance.create( logicClass );
                        if ( instance.created ) {
                            instance.created( this.changeModel.shapes[shape.id], def );
                        }
                        applyTextStyles();
                    }),
                );
            }),
            ignoreElements(),
        );
    }

    private createLinkedConnectors( shape, zIndex, def ) {
        const connectorIds = EntityLinkService.createLinkedConnectors(
            this.changeModel,
            shape,
            this.data.entity,
            this.state.get( 'projectEDataModels' ),
            zIndex,
            def,
        );
        if ( !shape.connectorIds ) {
            shape.connectorIds = [];
        }
        shape.connectorIds = [ ...shape.connectorIds, ...connectorIds ];
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( AddDiagramShape, 'name', {
    value: 'AddDiagramShape',
});
