import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter,
    ViewChild, ElementRef, AfterViewInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as innerText from '@creately/inner-text';
/**
 * This component is the editable label component.
 * The value on this label can be edited by clicking on it.
 *
 * @author nuwanthi
 * @since 2018-07-05
 */

@Component({
    templateUrl: './editable-label.cmp.html',
    selector: 'editable-label',
    styleUrls: [ './editable-label.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EditableLabel implements  AfterViewInit {

    /**
     * Text input value.
     */
    @Input() public value: string;

    @Input() public tooltip: string;

    /**
     * Defines if the component is read only.
     */
    @Input() public readonly: boolean = false;

    /**
     * Defines if the component allows empty values
     * to be emitted.
     */
    @Input() public allowEmpty: boolean = false;

    /**
     * Set the focus when created
     */
    @Input() public focus: boolean = false;

    /**
     * Select all when focused
     */
    @Input() public selectAll: boolean = false;

    /**
     * If set to true, will display a multilined component
     */
    @Input() public multiline: boolean = false;

    /**
     * Event emitter to emit the changed text.
     */
    @Output() public changedText: EventEmitter<string> = new EventEmitter();

    /**
     * Used to set a class "editable" for styling purposes.
     */
    public editable: boolean = false;

    /**
     * Stores the current value of the input.
     */
    public currentVal: string;

    /** emit if the value changed only. */
    @Input() public changesOnly = true;

    /**
     * Input item
     */
    @ViewChild( 'editableInput' )
    protected inputElement: ElementRef;

    constructor( protected sanitizer: DomSanitizer ) {}

    /**
     * Returns the text on the editable html element.
     */
    public get text(): string {
        return innerText( this.inputElement.nativeElement );
    }

    public ngAfterViewInit() {
        if ( this.inputElement && this.focus ) {
            // FIXME - focus method on the content editable divs not working without the time out
            setTimeout(() => {
                this.setFocus( this.selectAll );
            }, 0 );
        }
    }

    /**
     * Emits the text input value.
     */
    public emitChange( value: string ) {
        if ( this.changesOnly ) {
            if ( value === this.currentVal ) {
                return;
            }
        }
        if ( this.allowEmpty ) {
            this.changedText.next( value );
        } else {
            if ( value.trim()) {
                this.changedText.next( value );
            } else {
                this.setInputText( this.currentVal );
            }
        }
        this.editable = false;

        // This line of code adde to "Deselect all" on blur
        // to fix "Select all and click outside won't deselect the selection"
        window.getSelection().removeAllRanges();

        // Scroll left when the editable label focus out
        this.inputElement.nativeElement.scrollLeft = 0;
    }
    /**
     * Calls the blur function which will call the
     * emitChange() method that will emit the changes.
     */
    public emitChangeOnEnter( event: Event ) {
        ( <HTMLInputElement>event.target ).blur();
    }

    public closeWithoutSave( event: Event ) {
        this.setInputText( this.value || '' );
        ( <HTMLInputElement>event.target ).blur();
    }

    /**
     * Focus the editable input and select all the content if the
     * selectAll input paran is true.
     */
    public setFocus( selectAll: boolean = true ) {
        this.inputElement.nativeElement.focus();
        if ( selectAll ) {
            if ( this.inputElement.nativeElement.select ) {
                this.inputElement.nativeElement.select();
            } else if ( !window.getSelection().toString()) {
                window.getSelection().selectAllChildren( this.inputElement.nativeElement );
            }
        }
    }

    /**
     * Method that pastes text as plain text.
     */
    public onPaste( event: ClipboardEvent ) {
        event.preventDefault();
        const text = this.sanitizer.sanitize(
            SecurityContext.HTML,
            event.clipboardData.getData( 'text/plain' ),
        );
        document.execCommand( 'insertHTML', false, text );
    }

    /**
     * Method that sets the input value to a given value.
     */
    protected setInputText( value: string ) {
        ( this.inputElement.nativeElement as HTMLMapElement ).innerText = value;
    }
}
