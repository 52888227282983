import { Injectable } from '@angular/core';
import { Command, Random } from 'flux-core';
import { IConnectorDefinition  } from 'flux-definition';
import { IShapeTypeDefiner, DEFAULT_CONNECTOR, EDATAREF_CONNECTOR, LOOKUP_CONNECTOR } from 'flux-diagram-composer';
import { ConnectorModel, IConnectorTypeDefiner } from '../../../base/shape/model/connector.mdl';
import { INewConnectorConnection } from '../../../base/shape/connection/connection.i';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';

/**
 * SetConnectorConnection
 * Sets the type (defId and version) for one or more connectors. If null is given as the value
 * it will select one of the supported connector types or fallback to the default connector type.
 *
 * FIXME: updateConnections and updateDefinitions flags are only temporary fixes added to prevent
 *        the application from crashing. Ideally the connection should also be previewed.
 */
@Injectable()
@Command()
export class SetConnectorConnection extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        changes: {
            [connectorId: string]: IConnectorTypeDefiner | null,
        },
        updateConnections: boolean,
        updateDefinitions: boolean,
    };

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        for ( const shapeId in this.data.changes ) {
            const type = this.data.changes[shapeId];
            const connector = this.changeModel.shapes[shapeId] as ConnectorModel;
            // FIXME: This can be removed after selection blocker is implemented
            if ( !connector ) {
                return;
            }
            // NOTE: one or more endpoints are not connected
            if ( !type && ( !connector.getFromEndpoint( this.changeModel ).shape ||
                !connector.getToEndpoint( this.changeModel ).shape )) {
                    if ( connector.connectionId ) {
                        this.removeConnection( connector );
                    }
                    return;
            }
            // NOTE: a connection is not currently available
            if ( !connector.connectionId ) {
                this.createConnection( connector, type );
                return;
            }
            const connection = this.changeModel.connections[connector.connectionId];
            // FIXME: Handle cases when the type does not change ( given type
            //        or selected type is same as the current type.
            if ( connection && (
                type ||
                connection.shapeA.shapeId !== connector.getFromEndpoint( this.changeModel ).shape.id ||
                connection.shapeB.shapeId !== connector.getToEndpoint( this.changeModel ).shape.id )
            ) {
                // NOTE: current connection is not valid or different from given type
                this.removeConnection( connector );
                this.createConnection( connector, type );
            }
        }
    }

    /**
     * Updates the change model to remove an existing connection.
     */
    private removeConnection( connector: ConnectorModel ): void {
        if ( this.data.updateConnections ) {
            delete this.changeModel.connections[connector.connectionId];
            connector.connectionId = null;
        }
    }

    /**
     * Updates the change model to include a new connection.
     */
    private createConnection( connector: ConnectorModel, type: IConnectorTypeDefiner ): void {
        // if ( this.data.updateDefinitions ) {
        //     delete connector.handshake;
        // }
        // NOTE: if the user tries to use the default connector type, always allow.
        if ( type && type.defId === DEFAULT_CONNECTOR.defId ) {
            this.createDefaultConnection( connector );
        } else if ( type && type.defId === EDATAREF_CONNECTOR.defId ) {
            this.createEDataConnection( connector );
        } else if ( type && type.defId === LOOKUP_CONNECTOR.defId ) {
            this.createLookupConnection( connector, type );
        } else if ( type ) {
            this.createPickedConnection( connector, type );
        }
    }

    /**
     * Updates the change model to include a new default connection.
     */
    private createDefaultConnection( connector: ConnectorModel ): void {
        if ( this.data.updateDefinitions ) {
            connector.defId = DEFAULT_CONNECTOR.defId;
            connector.version = DEFAULT_CONNECTOR.version;
        }
    }

    /**
     * Updates the change model to include a new edata ref connection.
     */
     private createEDataConnection( connector: ConnectorModel ): void {
        if ( this.data.updateDefinitions ) {
            connector.defId = EDATAREF_CONNECTOR.defId;
            connector.version = EDATAREF_CONNECTOR.version;
        }
    }

    /**
     * Updates the change model to include a new edata ref connection.
     */
     private createLookupConnection( connector: ConnectorModel, type: IConnectorTypeDefiner ): void {
        if ( this.data.updateDefinitions ) {
            connector.defId = LOOKUP_CONNECTOR.defId;
            connector.version = LOOKUP_CONNECTOR.version;
            connector.handshake = type.handshake;
        }
    }

    /**
     * Updates the change model to include a new connection which will be selected.
     */
    private createPickedConnection( connector: ConnectorModel, type: IShapeTypeDefiner ): void {
        const shapeAId = connector.getFromEndpoint( this.changeModel ).shape.id;
        const shapeBId = connector.getToEndpoint( this.changeModel ).shape.id;
        const connections = this.changeModel.getPotentialConnections( shapeAId, shapeBId )
            .filter( conn => conn.connection.type === 'connector' );
        const connectionInfo = this.selectConnection( connections, type );
        if ( !connectionInfo ) {
            return;
        }
        // NOTE: set the connectionId on the connector
        const connectionId = Random.connectionId();
        const definition = this.selectDefinition( connectionInfo.definitions, type );
        const connection = connectionInfo.connection;
        if ( this.data.updateDefinitions ) {
            connector.defId = definition.defId;
            connector.version = definition.version;
        }
        if ( this.data.updateConnections ) {
            connector.connectionId = connectionId;
            this.changeModel.connections[connectionId] = connection;
        }
    }

    /**
     * Select a connection when a preferred definition is given by the user.
     */
    private selectConnection( cons: INewConnectorConnection[], type: IShapeTypeDefiner ): INewConnectorConnection {
        if ( !type ) {
            return cons[0];
        }
        const withDef = cons.find( con => !!con.definitions.find( def => def.defId === type.defId ));
        return withDef || cons[0];
    }

    /**
     * Select a definition when a preferred definition is given by the user
     */
    private selectDefinition( defs: IConnectorDefinition[], type: IShapeTypeDefiner ): IConnectorDefinition {
        if ( !type ) {
            return defs[0];
        }
        const matchingDef = defs.find( def => def.defId === type.defId );
        return matchingDef || defs[0];
    }
}

Object.defineProperty( SetConnectorConnection, 'name', {
    value: 'SetConnectorConnection',
});
